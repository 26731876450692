import {gql} from "@apollo/client";

export const GET_CERT_GEN_PREVIEW_DATA_MANDATE = gql`
  query bioLcGetCertGenPreviewData(
    $divisionId: String
    $siteReferenceId: String
    $documentType: String
    $mandateAllocationEuId: String
    $allocationGroupId: String
  ) {
    bioLcGetCertGenPreviewData(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        documentType: $documentType
        mandateAllocationEuId: $mandateAllocationEuId
        allocationGroupId: $allocationGroupId
      }
    ) {
      downloadUrl
      error
      statusCode
      templateData {
        allocationGroupId
        additionalInfo
        agriculturalBiomassIntermediateCropFlag
        agriculturalBiomassLowRiskFlag
        allocatedElectricity
        allocatedHeat
        bioProductProducer
        bioProductUser
        certificateNumber
        certificateVersionNo
        certificationSystem
        chainOfCustodyOption
        contractNumber
        defaultValueAppliedFlag
        documentMasterId
        euRedCompliantFlag
        ghgEccr
        ghgEccs
        ghgEec
        ghgEl
        ghgEmissionPercentageIn
        ghgEp
        ghgEsca
        ghgEtd
        ghgEu
        ghgTotal
        isccCompliantFlag
        isccIssuerLicense
        issueDatez
        materialDispatchDatez
        materialSustainabilityCriteriaFlag
        nuts2Region
        posIssueDatez
        producerInstallationDate
        productDensity
        productEnergyContent
        productType
        quantity
        quantityUom
        rawMaterialOriginCountryName
        rawMaterialType
        rawMaterialWasteOrResidueFlag
        recipientAddress
        recipientName
        recipientReceiptAddress
        relatedDocumentRetirementScheme
        sdNumber
        status
        supplierAddress
        supplierDispatchAddress
        supplierDispatchAddressSameFlag
        supplierName
        userInstallationDate
        deliveries {
          product
          deliveryDate
          quantity
          feedstock
          countryOfOrigin
          ghgTotal
          ghgEmissionPercentageIn
          incomingSdNumber
        }
      }
    }
  }
`;

export default {GET_CERT_GEN_PREVIEW_DATA_MANDATE};
