import {Check24} from "@bphxd/ds-core-react/lib/icons";
import Form from "modules/common/FormNew";
import SingleDocumentViewer from "modules/common/SingleDocumentViewer";
import {useAppSetting} from "providers/appSetting";
import React, {useMemo, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting";

import {useQuery} from "@apollo/client";

import ResponseModal from "modules/GlobalAllocation/components/ResponseModal";
import Layout from "modules/GlobalMassBalance/components/Layout";
import {useUserSettings} from "providers/userSettings";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";

import {CertificateGenerationMutationAPI} from "graphql/docManager/bioLcCertGenPdf";
import {GET_CERT_GEN_PREVIEW_DATA_MANDATE} from "graphql/mandate/bioLcGetCertGenPreviewDataMandate";
import {startCase} from "lodash";
import moment from "moment";
import {toast} from "react-toastify";
import Datepicker from "../components/Datepicker";
import "./styles.scss";

const defaultValues = {
  certificateNumber: "",
  supplierName: "",
  recipientName: "",
  contractNumber: "",
  supplierDispatchAddress: "",
  issueDatez: "",
  deliveries: [],
};

const MandateCertificateGeneratePTD = () => {
  const [open, setOpen] = useState([]);
  const [updateCertificateLoading, setUpdateCertificateLoading] =
    useState(false);

  const toggle = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((item) => item !== id));
    } else {
      setOpen([...open, id]);
    }
  };
  const [searchParams] = useSearchParams();
  const location = searchParams.get("location");
  const period = searchParams.get("period");
  const outboundType = searchParams.get("outboundType");

  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseHeader, setResponseHeader] = useState("");
  const [responseFooter, setResponseFooter] = useState([]);

  const {appSetting, setAppSetting} = useAppSetting();

  const navigate = useNavigate();
  const {country, mandateAllocationEuId, allocationGroupId, division} =
    useParams();
  setCountryDetails(country);

  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(division.toUpperCase());

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const {data, loading} = useQuery(GET_CERT_GEN_PREVIEW_DATA_MANDATE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
      documentType: "PTD",
      allocationGroupId,
      mandateAllocationEuId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const document = data?.bioLcGetCertGenPreviewData?.templateData;
  const downloadUrl = data?.bioLcGetCertGenPreviewData?.downloadUrl;

  const values = useMemo(() => {
    if (document) {
      const {
        certificateNumber = "",
        supplierName = "",
        recipientName = "",
        contractNumber = "",
        supplierDispatchAddress = "",
        issueDatez = "",
        deliveries = [],
      } = document;
      const props = {
        issueDatez:
          issueDatez == null || issueDatez === ""
            ? ""
            : moment(issueDatez, "YYYY/MM/DD").format(
                "DD-MM-YYYY HH:mm:ss.0 ZZ",
              ),
        certificateNumber,
        supplierName,
        recipientName,
        contractNumber,
        supplierDispatchAddress,
        deliveries: deliveries.map((delivery) => {
          const {
            product,
            deliveryDate,
            quantity,
            feedstock,
            countryOfOrigin,
            ghgTotal,
            ghgEmissionPercentageIn,
            incomingSdNumber,
          } = delivery;
          return {
            product,
            deliveryDate:
              deliveryDate == null || deliveryDate === ""
                ? ""
                : moment(deliveryDate, "YYYY/MM/DD").format(
                    "DD-MM-YYYY HH:mm:ss.0 ZZ",
                  ),
            quantity: quantity?.toString() ?? "",
            feedstock,
            countryOfOrigin,
            ghgTotal: ghgTotal?.toString() ?? "",
            ghgEmissionPercentageIn: ghgEmissionPercentageIn?.toString() ?? "",
            incomingSdNumber,
          };
        }),
      };
      return props;
    }
    return defaultValues;
  }, [document]);

  const methods = useForm({
    defaultValues,
    values,
  });

  const {
    register,
    control,
    formState: {errors},
  } = methods;

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const redirectUrl = appendParamsToUrl(
    "/mandate-certificate",
    [country, division],
    {
      location,
      period,
      outboundType,
    },
  );

  const handleSuccessResponse = () => {
    setResponseHeader(
      "Your certificate is now being generated. We will alert you as soon as it’s ready.",
    );
    setResponseFooter([
      {
        id: 1,
        text: "Return to mandate certificates",
        buttonClassName: "!text-[#111]",
        action: () => {
          navigate(redirectUrl);
        },
      },
    ]);
    setShowResponseModal(true);
  };

  const handleGenerateCertificate = async () => {
    setUpdateCertificateLoading(true);
    const {__typename, status, ...outgoingDocData} = document;
    const certData = {
      event: {
        divisionId: divisionData?.divisionId,
        siteReferenceId: siteReferenceData?.siteReferenceId,
        outgoingDocData: {
          ...outgoingDocData,
          deliveries: values.deliveries.map((delivery) => {
            const {
              product,
              deliveryDate,
              quantity,
              feedstock,
              countryOfOrigin,
              ghgTotal,
              ghgEmissionPercentageIn,
              incomingSdNumber,
            } = delivery;
            return {
              product,
              deliveryDate,
              quantity,
              feedstock,
              countryOfOrigin,
              ghgTotal,
              ghgEmissionPercentageIn,
              incomingSdNumber,
            };
          }),
        },
      },
    };
    const {data: genCertData} =
      await CertificateGenerationMutationAPI(certData);
    if (genCertData?.bioLcCertGenPdf?.statusCode === 201) {
      setAppSetting({
        ...appSetting,
        subscriptionDataGenerateCertificate: {
          certificateOutboundEuId:
            genCertData?.bioLcCertGenPdf?.certificateOutboundEuId,
          message: document?.certificateNumber,
          redirectTo: appendParamsToUrl(
            "/mass-balance/outgoing-view",
            [
              country,
              division,
              genCertData?.bioLcCertGenPdf?.certificateOutboundEuId,
            ],
            {redirectTo: redirectUrl},
          ),
        },
      });
      handleSuccessResponse();
    } else {
      toast.error("Failed to generate document.");
    }
    setUpdateCertificateLoading(false);
  };

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: divisionData?.divisionCode, link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },
    {
      text: "Mandate certificates",
      link: redirectUrl,
    },
    {text: document?.certificateNumber},
  ];

  if (
    loading ||
    siteReferenceData?.siteReferenceId === undefined ||
    divisionData?.divisionId === undefined
  ) {
    return (
      <div
        className="flex flex-col min-h-full items-center py-12 bp-core bg-light-gray-100"
        style={{
          height: "calc(100vh - 352px)",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div className="view-document flex flex-col view-doc__max-scroll bp-core bg-light-gray-100 ">
      <div className="flex flex-1 flex-row justify-between items-stretch min-h-full w-full">
        <div className="flex flex-col flex-1 max-h-screen overflow-y-auto">
          <Layout
            breadcrumbs={breadcrumbItems}
            showHR
            subtitle={
              <div className="flex flex-col justify-between items-start gap-2 small pb-[40px] px-7">
                <p className="text-[32px] mb-[16px] !font-light">
                  Generate certificate
                </p>
                <span className="mb-2">
                  Once you have reviewed the document, you can generate the
                  certificate. This will take approximately 20 seconds.
                </span>
              </div>
            }
          >
            <div className="flex flex-col p-7 min-h-[800px]">
              <FormProvider {...methods}>
                <Form className="flex flex-col !gap-[32px]">
                  <FormGroup>
                    <Label for="certificateNumber" className="fw-normal mb-4">
                      Document
                    </Label>
                    <Input
                      className="disabled-field !text-[#111111]/50"
                      type="text"
                      id="certificateNumber"
                      maxLength={100}
                      disabled
                      {...computeProps("certificateNumber")}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="supplierName" className="fw-normal mb-4">
                      Company name
                    </Label>
                    <Input
                      className="disabled-field !text-[#111111]/50"
                      type="text"
                      id="supplierName"
                      maxLength={100}
                      disabled
                      {...computeProps("supplierName")}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="recipientName" className="fw-normal mb-4">
                      Customer
                    </Label>
                    <Input
                      className="disabled-field !text-[#111111]/50"
                      type="text"
                      id="recipientName"
                      maxLength={100}
                      disabled
                      {...computeProps("recipientName")}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="contractNumber" className="fw-normal mb-4">
                      Contract number
                    </Label>
                    <Input
                      className="disabled-field !text-[#111111]/50"
                      type="text"
                      id="contractNumber"
                      maxLength={100}
                      disabled
                      {...computeProps("contractNumber")}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label
                      for="supplierDispatchAddress"
                      className="fw-normal mb-4"
                    >
                      Delivery location
                    </Label>
                    <Input
                      className="disabled-field !text-[#111111]/50"
                      type="text"
                      id="supplierDispatchAddress"
                      maxLength={100}
                      disabled
                      {...computeProps("supplierDispatchAddress")}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="issueDatez" className="fw-normal mb-4">
                      Date of issuance
                    </Label>
                    <Datepicker
                      className="disabled-field !text-[#111111]/50"
                      id="issueDatez"
                      name="issueDatez"
                      control={control}
                      error={errors.issueDatez}
                      rules={{
                        required: "Please enter date of issuance of the PoC",
                      }}
                      format="d/m/Y"
                    />
                  </FormGroup>
                  <Accordion open={open} {...{toggle}} flush>
                    <AccordionItem>
                      <AccordionHeader
                        targetId="deliveries"
                        className="[&>button]:pl-0 [&>button]:pr-0"
                      >
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100">
                          Delivery details
                        </h5>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="deliveries"
                        className="[&>div]:pl-0 [&>div]:pr-0"
                      >
                        <div className="flex flex-col gap-5 pb-8">
                          {values.deliveries.map((delivery, index) => (
                            <div key={index}>
                              <p className="text-[14px] !text-[#111] font-[500] tracking-[1.6px]">
                                DELIVERY {index + 1}
                              </p>
                              <FormGroup className="my-5">
                                <Label
                                  for={`deliveries.${index}.product`}
                                  className="fw-normal mb-4"
                                >
                                  Product / Production Process
                                </Label>
                                <Input
                                  className="disabled-field !text-[#111111]/50"
                                  type="select"
                                  id={`deliveries.${index}.product`}
                                  data-test={`deliveries.${index}.product`}
                                  {...computeProps(
                                    `deliveries.${index}.product`,
                                  )}
                                  disabled
                                  placeholder="Please choose from list"
                                >
                                  <option value="">
                                    Please choose from list
                                  </option>
                                  <option value={delivery.product}>
                                    {delivery.product}
                                  </option>
                                </Input>
                              </FormGroup>
                              <FormGroup>
                                <Label
                                  for={`deliveries.${index}.deliveryDate`}
                                  className="fw-normal mb-4"
                                >
                                  Delivery date
                                </Label>
                                <Datepicker
                                  className="disabled-field !text-[#111111]/50"
                                  id={`deliveries.${index}.deliveryDate`}
                                  name={`deliveries.${index}.deliveryDate`}
                                  control={control}
                                  rules={{
                                    required: "Please enter date of delivery",
                                  }}
                                  format="d/m/Y"
                                  disabled
                                />
                              </FormGroup>
                              <FormGroup className="my-5">
                                <Label
                                  for={`deliveries.${index}.quantity`}
                                  className="fw-normal mb-4"
                                >
                                  Bioquantity (L15)
                                </Label>
                                <Input
                                  className="disabled-field !text-[#111111]/50"
                                  type="text"
                                  id={`deliveries.${index}.quantity`}
                                  {...computeProps(
                                    `deliveries.${index}.quantity`,
                                  )}
                                  data-test={`deliveries.${index}.quantity`}
                                  disabled
                                />
                              </FormGroup>
                              <FormGroup className="my-5">
                                <Label
                                  for={`deliveries.${index}.feedstock`}
                                  className="fw-normal mb-4"
                                >
                                  Feedstock
                                </Label>
                                <Input
                                  className="disabled-field !text-[#111111]/50"
                                  type="select"
                                  id={`deliveries.${index}.feedstock`}
                                  data-test={`deliveries.${index}.feedstock`}
                                  {...computeProps(
                                    `deliveries.${index}.feedstock`,
                                  )}
                                  disabled
                                  placeholder="Please choose from list"
                                >
                                  <option value="">
                                    Please choose from list
                                  </option>
                                  <option value={delivery.feedstock}>
                                    {delivery.feedstock}
                                  </option>
                                </Input>
                              </FormGroup>
                              <FormGroup className="my-5">
                                <Label
                                  for={`deliveries.${index}.countryOfOrigin`}
                                  className="fw-normal mb-4"
                                >
                                  Feedstock country of origin
                                </Label>
                                <Input
                                  className="disabled-field !text-[#111111]/50"
                                  type="select"
                                  id={`deliveries.${index}.countryOfOrigin`}
                                  data-test={`deliveries.${index}.countryOfOrigin`}
                                  {...computeProps(
                                    `deliveries.${index}.countryOfOrigin`,
                                  )}
                                  disabled
                                  placeholder="Please choose from list"
                                >
                                  <option value="">
                                    Please choose from list
                                  </option>
                                  <option value={delivery.countryOfOrigin}>
                                    {delivery.countryOfOrigin}
                                  </option>
                                </Input>
                              </FormGroup>
                              <FormGroup className="my-5">
                                <Label
                                  for={`deliveries.${index}.ghgTotal`}
                                  className="fw-normal mb-4"
                                >
                                  Carbon intensity (gCO2e/MJ)
                                </Label>
                                <Input
                                  className="disabled-field !text-[#111111]/50"
                                  type="text"
                                  id={`deliveries.${index}.ghgTotal`}
                                  {...computeProps(
                                    `deliveries.${index}.ghgTotal`,
                                  )}
                                  data-test={`deliveries.${index}.ghgTotal`}
                                  disabled
                                />
                              </FormGroup>
                              <FormGroup className="my-5">
                                <Label
                                  for={`deliveries.${index}.ghgEmissionPercentageIn`}
                                  className="fw-normal mb-4"
                                >
                                  Saving (%)
                                </Label>
                                <Input
                                  className="disabled-field !text-[#111111]/50"
                                  type="text"
                                  id={`deliveries.${index}.ghgEmissionPercentageIn`}
                                  {...computeProps(
                                    `deliveries.${index}.ghgEmissionPercentageIn`,
                                  )}
                                  data-test={`deliveries.${index}.ghgEmissionPercentageIn`}
                                  disabled
                                />
                              </FormGroup>
                              <FormGroup className="my-5">
                                <Label
                                  for={`deliveries.${index}.incomingSdNumber`}
                                  className="fw-normal mb-4"
                                >
                                  PoS traceability reference
                                </Label>
                                <Input
                                  className="disabled-field !text-[#111111]/50"
                                  type="text"
                                  id={`deliveries.${index}.incomingSdNumber`}
                                  {...computeProps(
                                    `deliveries.${index}.incomingSdNumber`,
                                  )}
                                  data-test={`deliveries.${index}.incomingSdNumber`}
                                  disabled
                                />
                              </FormGroup>
                            </div>
                          ))}
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                  <div className="flex flex-row justify-end items-center gap-4">
                    <Button
                      color="tertiary"
                      className="show"
                      style={{
                        borderRadius: "0px",
                        borderColor: "#cccccc",
                      }}
                      type="button"
                      onClick={() => navigate(redirectUrl)}
                      disabled={updateCertificateLoading}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="standard-primary btn-bg-br-black"
                      style={{
                        borderRadius: "0px",
                        backgroundColor: "#232323",
                      }}
                      type="button"
                      disabled={!document || updateCertificateLoading}
                      onClick={handleGenerateCertificate}
                    >
                      {updateCertificateLoading ? (
                        <Spinner size="sm" className="btn-icon-prefix" />
                      ) : (
                        <Check24 className="btn-icon-prefix" />
                      )}
                      Generate certificate
                    </Button>
                  </div>
                </Form>
              </FormProvider>
            </div>
          </Layout>
        </div>
        <SingleDocumentViewer document={{downloadUrl}} isOutgoing />
      </div>
      {showResponseModal && (
        <ResponseModal
          showModal={showResponseModal}
          header={responseHeader}
          footerArr={responseFooter}
        />
      )}
    </div>
  );
};

export default MandateCertificateGeneratePTD;
