import {ReactTablePaginationRow} from "@bphxd/ds-core-react/lib/components/tables/react-table/ReactTablePaginationRow";
import {TriangleDown16, TriangleUp16} from "@bphxd/ds-core-react/lib/icons";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {customDateFilterFn} from "modules/SAF-dashboard/utils";
import SpinnerLoading from "modules/common/SpinnerLoading";
import PropTypes from "prop-types";
import {useUserSettings} from "providers/userSettings";
import {Fragment, useMemo, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {Input, Table} from "reactstrap";
import Filter from "../filters/Filters";
import "./results-table.css";

const Checkbox = (row) => (
  <Input
    type="checkbox"
    checked={row.row.getIsSelected()}
    onChange={row.row.getToggleSelectedHandler()}
  />
);

const TableCheckbox = (table) => (
  <div className="d-flex align-items-center">
    <Input
      type="checkbox"
      checked={table.table.getIsAllRowsSelected()}
      onChange={table.table.getToggleAllRowsSelectedHandler()}
    />
  </div>
);

const getColumns = (columns, enableRowSelection) => {
  const newColumns = enableRowSelection
    ? [
        {
          header: ({table}) => <TableCheckbox table={table} />,
          accessorKey: "checkbox",
          enableSorting: false,
          size: 25,
          cell: ({row}) => <Checkbox row={row} />,
        },
        ...columns,
      ]
    : columns;

  return newColumns;
};

const ResultsTable = ({
  columns,
  data,
  enableRowSelection,
  rowSelection,
  setRowSelection,
  className,
  loading,
  highlightedRows,
  filtering,
  setFiltering,
  columnFilter,
  setColumnFilter,
  type,
  pageIndex,
  showFilter,
}) => {
  const {
    userSettings: {dateFormat},
  } = useUserSettings();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isHighlight, setIsHighlight] = useState(true);

  const newColumns = useMemo(
    () => getColumns(columns, enableRowSelection),
    [columns, enableRowSelection],
  );

  const table = useReactTable({
    data,
    columns: newColumns,
    showFilter,
    enableRowSelection,
    enableSubRowSelection: false,

    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    filterFns: {
      dateRangeFilterFn: (row, columnId, value) =>
        customDateFilterFn(row, columnId, value, dateFormat),
    },

    state: {
      rowSelection,
      columnFilters: columnFilter,
    },
    onColumnFiltersChange: setColumnFilter,
    onGlobalFilterChange: setFiltering,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const paginationProps = {
    rowCount: table.getPrePaginationRowModel().rows.length,
    pageLength: table.getRowModel().rows.length, // number of items on the current page
    canPreviousPage: table.getCanPreviousPage(),
    canNextPage: table.getCanNextPage(),
    pageCount: table.getPageCount(), // total number of pages
    gotoPage: table.setPageIndex,
    nextPage: table.nextPage,
    previousPage: table.previousPage,
    setPageSize: table.setPageSize,
    pageIndex: table.getState().pagination.pageIndex,
    pageSize: table.getState().pagination.pageSize, // user setting from Select input
    showFirstAndLast: false,
    showPageInput: false,
    fontSize: "md", // 'sm' or 'lg' (default is md)
  };

  const totalData = table.getPrePaginationRowModel().rows.length;

  const gettableHeader = (headerItem) => {
    return headerItem.column.getCanSort() ? (
      <th
        style={{
          cursor: "pointer",
          userSelect: "none",
          width: headerItem.getSize(),
        }}
      >
        <div
          className={`d-flex align-items-center ${
            headerItem.getContext().column.columnDef.dataType === "number" &&
            "justify-content-end"
          }`}
          onClick={headerItem.column.getToggleSortingHandler()}
          onKeyDown={() => {}}
        >
          <div>
            {flexRender(
              headerItem.column.columnDef.header,
              headerItem.getContext(),
            )}
          </div>
          <div className="position-relative ms-2">
            <div className="react-table-sort position-absolute top-50 translate-middle-y">
              <TriangleDown16
                className={`position-absolute bottom-0 ${
                  !headerItem.column.getIsSorted() ||
                  (headerItem.column.getIsSorted() &&
                    headerItem.column.getIsSorted() !== "desc")
                    ? "opacity-20"
                    : ""
                }`}
                onClick={() => {
                  if (type === "Results" && headerItem.id === "status") {
                    searchParams.delete("sortBy");
                    setSearchParams(searchParams);
                  }
                }}
              />
              <TriangleUp16
                className={`position-absolute top-0 ${
                  !headerItem.column.getIsSorted() ||
                  (headerItem.column.getIsSorted() &&
                    headerItem.column.getIsSorted() !== "asc")
                    ? "opacity-20"
                    : ""
                }`}
                onClick={() => {
                  if (headerItem.id === "status") {
                    searchParams.delete("sortBy");
                    setSearchParams(searchParams);
                  }
                }}
              />
            </div>
          </div>
        </div>
        {headerItem.column.getCanFilter() && showFilter ? (
          <div className="mt-[10px]">
            <Filter
              column={headerItem.column}
              data={data}
              isParentKey={false}
            />
          </div>
        ) : null}
      </th>
    ) : (
      <th
        className="align-top"
        style={{
          width: headerItem.getSize(),
        }}
      >
        {flexRender(
          headerItem.column.columnDef.header,
          headerItem.getContext(),
        )}
      </th>
    );
  };

  return (
    <div className="rules-engine-results-list">
      <ReactTablePaginationRow {...paginationProps} />
      <div className="w-full overflow-x-auto h-[550px]">
        <Table className="rule-engine-results-table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Fragment key={header.id}>
                    {header.isPlaceholder ? (
                      <th aria-label="placeholder" />
                    ) : (
                      gettableHeader(header)
                    )}
                  </Fragment>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {loading && (
              <tr>
                <td colSpan="100%">
                  <div className="flex items-center justify-center ">
                    <SpinnerLoading />
                  </div>
                </td>
              </tr>
            )}
            {!loading &&
              totalData > 0 &&
              table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            {!loading && totalData === 0 && !filtering && (
              <tr>
                <td colSpan="100%">
                  <div className="flex items-center justify-center error-message-table ">
                    No Data
                  </div>
                </td>
              </tr>
            )}
            {!loading && totalData === 0 && filtering && (
              <tr>
                <td colSpan="100%">
                  <div className="pt-7 pl-5 error-message-table ">
                    No results found for the search criteria.
                    <span
                      className="underline cursor-pointer"
                      onClick={() => setFiltering("")}
                      onKeyDown={() => setFiltering("")}
                    >
                      Clear search
                    </span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <ReactTablePaginationRow {...paginationProps} />
    </div>
  );
};

ResultsTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  enableRowSelection: PropTypes.bool,
  rowSelection: PropTypes.object,
  setRowSelection: PropTypes.func,
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  highlightedRows: PropTypes.array,
  filtering: PropTypes.string,
  setFiltering: PropTypes.func,
  columnFilter: PropTypes.string,
  setColumnFilter: PropTypes.func,
  type: PropTypes.string,
  pageIndex: PropTypes.number,
  showFilter: PropTypes.bool,
};

export default ResultsTable;
