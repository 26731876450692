/* eslint-disable prettier/prettier */
import {gql} from "@apollo/client";

export const COPRO_US_BATCHES_API_DATA = gql`
  query GetCoproUsBatchesApiDataQuery(
    $op: String
    $c14_id: String
    $updates: [BatchesUsDetailsUpdate]
  ) {
    bioLcCoproUsBatchesApi(
      event: {op: $op, c14_id: $c14_id, updates: $updates}
    ) {
      statusCode
      body {
        details {
          c14_detail_id
          c14_certificate_number
          submitter
          submitter_label
          company_name
          lab_number
          percentage_modern_carbon
          percentage_modern_carbon_var
          testing_method
          date_received
          date_submitted
          batch_id
        }
        batch_ids {
          batch_id
        }
      }
    }
  }
`;

export default COPRO_US_BATCHES_API_DATA;

export const GET_TANKS = gql`
  query GetTanks {
    bioLcCoproUsBatchesApi(event: {op: "get_tanks"}) {
      statusCode
      body {
        tanks {
          tank_number
          tank_detail_id
          latest_date
          latest_end_date
        }
      }
    }
  }
`;

export const BATCH_IDS_QUERY = gql`
  query getBatchIds($startTime: String, $endTime: String, $tankNumber: String) {
    bioLcCoproUsBatchesApi(
      event: {
        op: "get_batch_ids"
        start_time: $startTime
        end_time: $endTime
        tank_number: $tankNumber
      }
    ) {
      statusCode
      body {
        batch_ids {
          batch_id
          batch_code
          tank_certified_date
          source_sample_code
        }
      }
    }
  }
`;

// Story 399833 / Sprint 66 - Comment out emts and state statuses until further notice
export const COPRO_US_BATCHES_DETAILS_API = gql`
  query GetCoproUsBatchesApiDataQuery(
    $op: String
    $batch_tab: String
    $dateRange: [String]
    $includeDetails: Boolean
    $historical: Boolean
  ) {
    bioLcCoproUsBatchesApi(
      event: {
        op: $op
        batch_tab: $batch_tab
        date_range: $dateRange
        details: $includeDetails
        historical: $historical
      }
    ) {
      statusCode
      body {
        batches {
          batch_status
          tank_number
          batch_id
          batch_resource_id
          batch_start_time
          batch_end_time
          operating_condition
          tank_volume
          tank_certified_datez
          batch_notes
          c14_detail_id
          watchlist
          missing_c14
          c14_test_date
          c14_pmc
          c14_sample_name
          c14_sample_date
          details {
            cold_recirc_bbl
            cold_recirc_lb
            hot_diesel_recirc_bbl
            hot_diesel_recirc_lb
            renewable_feed_bbl
            renewable_feed_lb
            rundown_density_lb_kgal
            total_feed_bbl
            total_feed_lb
            unit_rundown_bbl
            unit_rundown_lb
            cold_recirc_in_renewable_feed_bbl
            cold_recirc_in_renewable_feed_lb
            renewable_feed_tank_col_su_sd_bbl
            renewable_feed_tank_col_su_sd_lb
            batch_created_status
            shipment_linked_status
            c14_linked_status
            feedstock_allocated_status
            # emts_submitted_status
            # state_submitted_status
            # state_final_uploaded_status
            completion_percentage
          }
        }
      }
    }
  }
`;

export const COPRO_US_BATCHES_WATCHLIST_API = gql`
  query UpdateWatchlistItem($watchlist: BatchesUsWatchlistUpdate) {
    bioLcCoproUsBatchesApi(
      event: {op: "update_watchlist", watchlist: $watchlist}
    ) {
      statusCode
    }
  }
`;

export const COPRO_US_BATCH_DAYS_REMAINING = gql`
  query GetBatchDaysRemaining($c14_id: String) {
    bioLcCoproUsBatchesApi(event: {op: "get_days_remaining", c14_id: $c14_id}) {
      statusCode
      body {
        days_remaining
      }
    }
  }
`;

export const GET_BATCH_IDS = gql`
  query getBatchIds(
    $startTime: String
    $endTime: String
    $tankNumber: String
    $operatingCondition: String
  ) {
    bioLcCoproUsBatchesApi(
      event: {
        op: "get_batch_ids"
        start_time: $startTime
        end_time: $endTime
        tank_number: $tankNumber
        operating_condition: $operatingCondition
      }
    ) {
      statusCode
      body {
        batch_ids {
          batch_id
          batch_code
          tank_certified_date
          source_sample_code
          tank_volume
        }
      }
    }
  }
`;

export const GET_SPLIT_BATCH_IDS = gql`
  query getSplitBatchIds($tankNumber: String) {
    bioLcCoproUsBatchesApi(
      event: {op: "get_split_batch_ids", tank_number: $tankNumber}
    ) {
      statusCode
      body {
        split_batch_ids {
          batch_id
          next_split
          existing_splits
          tank_certified_datez
        }
      }
    }
  }
`;

export const GET_VALVE_STATUS = gql`
  query valveStatusOpen($start_time: String, $end_time: String) {
    bioLcCoproUsBatchesApi(
      event: {
        op: "get_valve_status"
        start_time: $start_time
        end_time: $end_time
      }
    ) {
      statusCode
      body {
        valve_open
      }
    }
  }
`;

export const DELETE_BATCH_BY_ID = gql`
  query DeleteBatchByID($op: String, $batch_id: String) {
    bioLcCoproUsBatchesApi(event: {op: $op, batch_id: $batch_id}) {
      statusCode
      error
    }
  }
`;

export const SAVE_BATCH = gql`
  query SaveBatch($batch_details: BatchesUsSaveBatchApi) {
    bioLcCoproUsBatchesApi(
      event: {op: "save_batch", batch_details: $batch_details}
    ) {
      statusCode
      error
    }
  }
`;

// Story 399833 / Sprint 66 - Comment out emts and state statuses until further notice
export const GET_BATCH_BY_ID = gql`
  query GetBatchByID($batch_id: String) {
    bioLcCoproUsBatchesApi(event: {op: "get_batch", batch_id: $batch_id}) {
      statusCode
      error
      body {
        batch_details {
          batch_id
          batch_resource_id
          batch_code
          batch_status
          batch_start_time
          batch_end_time
          source_sample_code
          tank_detail_id
          tank_certified_datez
          tank_number
          tank_volume
          operating_condition
          renewable_feed_quantity
          batch_notes
          c14_detail_id
          missing_c14
          c14_pmc
          c14_sample_date
          c14_sample_name
          split_batch
          heel_volume
          watchlist
          transfers {
            shipment_name
            source_batch_code
            destination_batch_code
          }
          details {
            renewable_feed_bbl
            cold_recirc_in_renewable_feed_bbl
            renewable_feed_tank_col_su_sd_bbl
            total_feed_bbl
            unit_rundown_bbl
            hot_diesel_recirc_bbl
            cold_recirc_bbl
            renewable_feed_lb
            cold_recirc_in_renewable_feed_lb
            renewable_feed_tank_col_su_sd_lb
            total_feed_lb
            unit_rundown_lb
            cold_recirc_lb
            rundown_density_lb_kgal
            hot_diesel_recirc_lb
            batch_created_status
            shipment_linked_status
            c14_linked_status
            feedstock_allocated_status
            net_renewable_feed_bbl
            total_production_bbl
            net_renewable_feed_lb
            total_production_lb
            # emts_submitted_status
            # state_submitted_status
            # state_final_uploaded_status
            completion_percentage
          }
        }
      }
    }
  }
`;

export const COPRO_US_BATCH_ALLOCATION_INFO = gql`
  query GetBatchAllocationByID($batch_resource_id: String) {
    bioLcCoproUsBatchesApi(
      event: {
        op: "get_batch_bol_allocation_by_batch_id"
        batch_resource_id: $batch_resource_id
      }
    ) {
      body {
        batch_allocation {
          feedstock_type
          feedstock_percentage
          feedstock_amount
        }
      }
      statusCode
      error
    }
  }
`;

export const UPDATE_BATCH = gql`
  query UpdateBatch(
    $batch_resource_id: String
    $updates: [BatchesUsDetailsUpdate]
  ) {
    bioLcCoproUsBatchesApi(
      event: {
        op: "update_batch"
        batch_resource_id: $batch_resource_id
        updates: $updates
      }
    ) {
      statusCode
      error
    }
  }
`;

export const GET_READY_BATCH = gql`
  query GetReadyBatches {
    bioLcCoproUsApi(event: {tracking_type: "get_ready_batches"}) {
      body {
        ready_batches
      }
      statusCode
      error
    }
  }
`;

export const SAVE_READY_BATCH = gql`
  query SaveReadyBatches {
    bioLcCoproUsApi(event: {tracking_type: "save_ready_batches"}) {
      body {
        ready_batches
      }
      statusCode
      error
    }
  }
`;
