import {Check24} from "@bphxd/ds-core-react/lib/icons";
import CheckboxFlag from "modules/common/BinaryFlag";
import Form from "modules/common/FormNew";
import SingleDocumentViewer from "modules/common/SingleDocumentViewer";
import {useAppSetting} from "providers/appSetting";
import React, {useMemo, useState} from "react";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting";

import {useQuery} from "@apollo/client";

import ResponseModal from "modules/GlobalAllocation/components/ResponseModal";
import Layout from "modules/GlobalMassBalance/components/Layout";
import {useUserSettings} from "providers/userSettings";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";

import {CertificateGenerationMutationAPI} from "graphql/docManager/bioLcCertGenPdf";
import {GET_CERT_GEN_PREVIEW_DATA_MANDATE} from "graphql/mandate/bioLcGetCertGenPreviewDataMandate";
import {startCase} from "lodash";
import moment from "moment";
import {toast} from "react-toastify";
import Datepicker from "../components/Datepicker";
import {formatDate} from "../utils";
import "./styles.scss";

const defaultValues = {
  date_of_issuance: "",
  supplier_name: "",
  supplier_address: "",
  certification_system: "",
  certificate_number: "",
  iscc_issuer_license: "",
  recipient_name: "",
  recipient_address: "",
  contract_number: "",
  supplier_dispatch_address: "",
  material_dispatch_datez: "",
  product_type: "",
  raw_material_type: "",
  additional_info: "",
  raw_material_origin_country_name: "",
  product_qty: "",
  product_qty_uom: "",
  product_energy_content: "",
  eu_red_compliant_flag: false,
  chain_of_custody_option: "",
  material_sustainability_criteria_flag: false,
  raw_material_waste_or_residue_flag: false,
  default_value_applied_flag: false,
  ghg_eec: 0,
  ghg_el: 0,
  ghg_ep: 0,
  ghg_etd: 0,
  ghg_eu: 0,
  ghg_esca: 0,
  ghg_eccs: 0,
  ghg_eccr: 0,
  ghg_total: "",
  allocated_heat: "",
  allocated_electricity: "",
  ghg_emission_percentage_in: "",
  supplier_dispatch_address_same_flag: false,
};

const MandateCertificateGeneratePOC = () => {
  const [open, setOpen] = useState([]);
  const [updateCertificateLoading, setUpdateCertificateLoading] =
    useState(false);

  const toggle = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((item) => item !== id));
    } else {
      setOpen([...open, id]);
    }
  };
  const [searchParams] = useSearchParams();
  const location = searchParams.get("location");
  const period = searchParams.get("period");
  const outboundType = searchParams.get("outboundType");

  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseHeader, setResponseHeader] = useState("");
  const [responseFooter, setResponseFooter] = useState([]);

  const {appSetting, setAppSetting} = useAppSetting();

  const navigate = useNavigate();
  const {country, mandateAllocationEuId, division} = useParams();
  setCountryDetails(country);

  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(division.toUpperCase());

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const {data, loading} = useQuery(GET_CERT_GEN_PREVIEW_DATA_MANDATE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
      documentType: "POC",
      mandateAllocationEuId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const document = data?.bioLcGetCertGenPreviewData?.templateData;
  const downloadUrl = data?.bioLcGetCertGenPreviewData?.downloadUrl;

  const values = useMemo(() => {
    if (document) {
      const {
        issueDatez = "",
        posIssueDatez = "",
        supplierName = "",
        supplierAddress = "",
        certificationSystem = "",
        certificateNumber = "",
        isccIssuerLicense = "",
        recipientName = "",
        recipientAddress = "",
        contractNumber = "",
        supplierDispatchAddress = "",
        materialDispatchDatez = "",
        productType = "",
        rawMaterialType = "",
        additionalInfo = "",
        rawMaterialOriginCountryName = "",
        quantity = "",
        quantityUom = "",
        productEnergyContent = "",
        euRedCompliantFlag = false,
        chainOfCustodyOption = "",
        materialSustainabilityCriteriaFlag = false,
        rawMaterialWasteOrResidueFlag = false,
        defaultValueAppliedFlag = "",
        ghgEec = 0,
        ghgEl = 0,
        ghgEp = 0,
        ghgEtd = 0,
        ghgEu = 0,
        ghgEsca = 0,
        ghgEccs = 0,
        ghgEccr = 0,
        ghgTotal = "",
        allocatedHeat = "",
        allocatedElectricity = "",
        ghgEmissionPercentageIn = "",
        supplierDispatchAddressSameFlag = false,
        relatedDocumentRetirementScheme = "",
        sdNumber = "",
      } = document;
      const props = {
        date_of_issuance_poc:
          issueDatez == null || issueDatez === ""
            ? ""
            : moment(issueDatez, "YYYY/MM/DD").format(
                "DD-MM-YYYY HH:mm:ss.0 ZZ",
              ),
        date_of_issuance_pos:
          posIssueDatez == null || posIssueDatez === ""
            ? ""
            : moment(posIssueDatez, "YYYY/MM/DD").format(
                "DD-MM-YYYY HH:mm:ss.0 ZZ",
              ),
        supplier_name: supplierName,
        supplier_address: supplierAddress,
        certification_system: certificationSystem,
        certificate_number: certificateNumber,
        iscc_issuer_license: isccIssuerLicense,
        recipient_name: recipientName,
        recipient_address: recipientAddress,
        contract_number: contractNumber,
        supplier_dispatch_address: supplierDispatchAddress,
        material_dispatch_datez:
          materialDispatchDatez == null || materialDispatchDatez === ""
            ? ""
            : formatDate(materialDispatchDatez, dateFormat),
        // materialDispatchDatez =
        product_type: productType,
        raw_material_type: rawMaterialType,
        additional_info: additionalInfo,
        raw_material_origin_country_name: rawMaterialOriginCountryName,
        product_qty: quantity,
        product_qty_uom: quantityUom, // capital or small earlier capital
        product_energy_content: productEnergyContent,
        eu_red_compliant_flag: euRedCompliantFlag,
        chain_of_custody_option: chainOfCustodyOption,
        material_sustainability_criteria_flag:
          materialSustainabilityCriteriaFlag,
        raw_material_waste_or_residue_flag: rawMaterialWasteOrResidueFlag,
        default_value_applied_flag: defaultValueAppliedFlag,
        ghg_eec: ghgEec,
        ghg_el: ghgEl,
        ghg_ep: ghgEp,
        ghg_etd: ghgEtd,
        ghg_eu: ghgEu,
        ghg_esca: ghgEsca,
        ghg_eccs: ghgEccs,
        ghg_eccr: ghgEccr,
        ghg_total: ghgTotal,
        allocated_heat: allocatedHeat,
        allocated_electricity: allocatedElectricity,
        ghg_emission_percentage_in: ghgEmissionPercentageIn,
        supplier_dispatch_address_same_flag: supplierDispatchAddressSameFlag,
        relatedDocumentRetirementScheme,
        sdNumber,
      };
      return props;
    }
    return defaultValues;
  }, [document, dateFormat]);

  const methods = useForm({
    defaultValues,
    values,
  });

  const {
    register,
    control,
    formState: {errors},
  } = methods;

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const redirectUrl = appendParamsToUrl(
    "/mandate-certificate",
    [country, division],
    {
      location,
      period,
      outboundType,
    },
  );

  const handleSuccessResponse = () => {
    setResponseHeader(
      "Your certificate is now being generated. We will alert you as soon as it’s ready.",
    );
    setResponseFooter([
      {
        id: 1,
        text: "Return to mandate certificates",
        buttonClassName: "!text-[#111]",
        action: () => {
          navigate(redirectUrl);
        },
      },
    ]);
    setShowResponseModal(true);
  };

  const handleGenerateCertificate = async () => {
    setUpdateCertificateLoading(true);
    const {__typename, status, ...outgoingDocData} = document;
    const certData = {
      event: {
        divisionId: divisionData?.divisionId,
        siteReferenceId: siteReferenceData?.siteReferenceId,
        outgoingDocData: {
          mandateAllocationEuId,
          ...outgoingDocData,
        },
      },
    };

    const {data: genCertData} =
      await CertificateGenerationMutationAPI(certData);
    if (genCertData?.bioLcCertGenPdf?.statusCode === 201) {
      setAppSetting({
        ...appSetting,
        subscriptionDataGenerateCertificate: {
          certificateOutboundEuId:
            genCertData?.bioLcCertGenPdf?.certificateOutboundEuId,
          message: document?.certificateNumber,
          redirectTo: appendParamsToUrl(
            "/mass-balance/outgoing-view",
            [
              country,
              division,
              genCertData?.bioLcCertGenPdf?.certificateOutboundEuId,
            ],
            {
              redirectTo: redirectUrl,
            },
          ),
        },
      });
      handleSuccessResponse();
    } else {
      toast.error("Failed to generate document.");
    }
    setUpdateCertificateLoading(false);
  };

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: divisionData?.divisionCode, link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },
    {
      text: "Mandate certificates",
      link: redirectUrl,
    },
    {text: document?.certificateNumber},
  ];

  const productTypeItems = useMemo(
    () => [{product_name: values?.product_type}],
    [values?.product_type],
  );

  const rawMaterialTypes = useMemo(
    () => [{raw_material_name: values?.raw_material_type}],
    [values?.raw_material_type],
  );

  const chainOfCustody = ["Mass balance", "Physical segregation"];

  if (
    loading ||
    siteReferenceData?.siteReferenceId === undefined ||
    divisionData?.divisionId === undefined
  ) {
    return (
      <div
        className="flex flex-col min-h-full items-center py-12 bp-core bg-light-gray-100"
        style={{
          height: "calc(100vh - 352px)",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div className="view-document flex flex-col view-doc__max-scroll bp-core bg-light-gray-100 ">
      <div className="flex flex-1 flex-row justify-between items-stretch min-h-full w-full">
        <div className="flex flex-col flex-1 max-h-screen overflow-y-auto">
          <Layout
            breadcrumbs={breadcrumbItems}
            showHR
            subtitle={
              <div className="flex flex-col justify-between items-start gap-2 small pb-[40px] px-7">
                <p className="text-[32px] mb-[16px] !font-light">
                  Generate certificate
                </p>
                <span className="mb-2">
                  Once you have reviewed the document, you can generate the
                  certificate. This will take approximately 20 seconds.
                </span>
              </div>
            }
          >
            <div className="flex flex-col p-7 min-h-[800px]">
              <FormProvider {...methods}>
                <Form className="flex flex-col !gap-[32px]">
                  <FormGroup>
                    <Label
                      for="relatedDocumentRetirementScheme"
                      className="fw-normal mb-4"
                    >
                      Please indicate under which fuel supplier obligation or
                      incentive scheme the original PoS document was retired
                      under (e.g., UK RTFO)
                    </Label>
                    <Input
                      className="disabled-field !text-[#111111]/50"
                      type="text"
                      id="relatedDocumentRetirementScheme"
                      maxLength={100}
                      disabled
                      {...computeProps("relatedDocumentRetirementScheme")}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="certificate_number" className="fw-normal mb-4">
                      Unique Number of the PoC
                    </Label>
                    <Input
                      className="disabled-field !text-[#111111]/50"
                      type="text"
                      id="certificate_number"
                      maxLength={100}
                      disabled
                      {...computeProps("certificate_number")}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label
                      for="date_of_issuance_poc"
                      className="fw-normal mb-4"
                    >
                      Date of Issuance of the PoC
                    </Label>
                    <Datepicker
                      className="disabled-field"
                      id="date_of_issuance_poc"
                      name="date_of_issuance_poc"
                      control={control}
                      error={errors.date_of_issuance_poc}
                      rules={{
                        required: "Please enter date of issuance of the PoC",
                      }}
                      format="d/m/Y"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="sdNumber" className="fw-normal mb-4">
                      Unique Number of the underlying PoS
                    </Label>
                    <Input
                      className="disabled-field !text-[#111111]/50"
                      type="text"
                      id="sdNumber"
                      maxLength={100}
                      disabled
                      {...computeProps("sdNumber")}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label
                      for="date_of_issuance_pos"
                      className="fw-normal mb-4"
                    >
                      Date of Issuance of the PoS (if available)
                    </Label>
                    <Datepicker
                      className="disabled-field !text-[#111111]/50"
                      id="date_of_issuance_pos"
                      name="date_of_issuance_pos"
                      control={control}
                      error={errors.date_of_issuance_pos}
                      rules={{required: "Please enter date of issuance"}}
                      format="d/m/Y"
                      disabled
                    />
                  </FormGroup>
                  <Accordion open={open} {...{toggle}} flush>
                    <AccordionItem>
                      <AccordionHeader
                        targetId="supplier"
                        className="[&>button]:pl-0 [&>button]:pr-0"
                      >
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100">
                          Supplier and recipient
                        </h5>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="supplier"
                        className="[&>div]:pl-0 [&>div]:pr-0"
                      >
                        <div className="flex flex-col gap-5 pb-8">
                          <FormGroup className="my-5">
                            <Label
                              for="supplier_name"
                              className="fw-normal mb-4"
                            >
                              Name of supplier
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="text"
                              id="supplier_name"
                              {...computeProps("supplier_name")}
                              data-test="supplier_name"
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="supplier_address"
                              className="fw-normal mb-4"
                            >
                              Address of supplier
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="text"
                              id="supplier_address"
                              {...computeProps("supplier_address")}
                              data-test="supplier_address"
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="certification_system"
                              className="fw-normal mb-4"
                            >
                              Certification system
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="text"
                              id="certification_system"
                              data-test="certification_system"
                              {...computeProps("certification_system")}
                              disabled
                            >
                              <option value="ISCC-EU">ISCC-EU</option>
                            </Input>
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="iscc_issuer_license"
                              className="fw-normal mb-4"
                            >
                              Certificate number
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="text"
                              id="iscc_issuer_license"
                              data-test="iscc_issuer_license"
                              {...computeProps("iscc_issuer_license")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="recipient_name"
                              className="fw-normal mb-4"
                            >
                              Name of recipient
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="text"
                              id="recipient_name"
                              data-test="recipient_name"
                              {...computeProps("recipient_name")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="recipient_address"
                              className="fw-normal mb-4"
                            >
                              Address of recipient
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="text"
                              id="recipient_address"
                              data-test="recipient_address"
                              {...computeProps("recipient_address")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="contract_number"
                              className="fw-normal mb-4"
                            >
                              Contract number
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="text"
                              id="contract_number"
                              data-test="contract_number"
                              {...computeProps("contract_number")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="supplier_dispatch_address"
                              className="fw-normal mb-4"
                            >
                              Address of dispatch/shipping point
                            </Label>
                            <Input
                              className="disabled-field mb-[10px] !text-[#111111]/50"
                              type="text"
                              id="supplier_dispatch_address"
                              data-test="supplier_dispatch_address"
                              {...computeProps("supplier_dispatch_address")}
                              disabled
                            />
                            <Input
                              className="mr-[15px] !text-[#111111]/50"
                              type="checkbox"
                              id="supplier_dispatch_address_same_flag"
                              data-test="supplier_dispatch_address_same_flag"
                              {...computeProps(
                                "supplier_dispatch_address_same_flag",
                              )}
                              disabled
                            />
                            <Label
                              check
                              for="supplier_dispatch_address_same_flag"
                            >
                              Same as address of supplier
                            </Label>
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="material_dispatch_datez"
                              className="fw-normal mb-4"
                            >
                              Date of dispatch
                            </Label>
                            <Datepicker
                              id="material_dispatch_datez"
                              name="material_dispatch_datez"
                              control={control}
                              disabled
                              format="d/m/Y"
                              className="!text-[#111111]/50"
                            />
                          </FormGroup>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader
                        targetId="general"
                        className="[&>button]:pl-0 [&>button]:pr-0"
                      >
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100">
                          General information
                        </h5>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="general"
                        className="[&>div]:pl-0 [&>div]:pr-0"
                      >
                        <div className="flex flex-col gap-5 pb-8">
                          <FormGroup className="my-5">
                            <Label
                              for="product_type"
                              className="fw-normal mb-4"
                            >
                              Type of product
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="select"
                              id="product_type"
                              data-test="product_type"
                              {...computeProps("product_type")}
                              disabled
                              placeholder="Please choose from list"
                            >
                              <option value="">Please choose from list</option>
                              {productTypeItems.map((productTypeItem) => (
                                <option
                                  key={productTypeItem.product_name}
                                  value={productTypeItem.product_name}
                                >
                                  {productTypeItem.product_name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="raw_material_type"
                              className="fw-normal mb-4"
                            >
                              Type of raw material
                            </Label>

                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="select"
                              id="raw_material_type"
                              data-test="raw_material_type"
                              {...computeProps("raw_material_type")}
                              disabled
                              placeholder="Please choose from list"
                            >
                              <option value="">Please choose from list</option>
                              {rawMaterialTypes.length > 0 &&
                                rawMaterialTypes.map((p) => (
                                  <option
                                    key={p.raw_material_name}
                                    value={p.raw_material_name}
                                  >
                                    {p.raw_material_name}
                                  </option>
                                ))}
                            </Input>
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="additional_info"
                              className="fw-normal mb-4"
                            >
                              Additional information (voluntary)
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="textarea"
                              rows="3"
                              id="additional_info"
                              data-test="additional_info"
                              {...computeProps("additional_info")}
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="raw_material_origin_country_name"
                              className="fw-normal mb-4"
                            >
                              Country of origin of the origin raw material
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="text"
                              id="raw_material_origin_country_name"
                              data-test="raw_material_origin_country_name"
                              {...computeProps(
                                "raw_material_origin_country_name",
                              )}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label for="product_qty" className="fw-normal mb-4">
                              Quantity
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="number"
                              id="product_qty"
                              data-test="product_qty"
                              {...computeProps("product_qty")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="product_qty_uom"
                              className="fw-normal mb-4"
                            >
                              Unit of measure
                            </Label>
                            <Controller
                              render={({field: {onChange, value, ref}}) => {
                                const m3 = {
                                  checked: value?.toLowerCase() === "m3",
                                };
                                const metricTons = {
                                  checked: value?.toLowerCase() === "mt",
                                };
                                return (
                                  <div>
                                    <FormGroup
                                      check
                                      inline
                                      className="form-check-alt form-check-lg !mb-0"
                                    >
                                      <Input
                                        innerRef={ref}
                                        id="m3"
                                        type="checkbox"
                                        data-test="m3"
                                        disabled
                                        onChange={(e) =>
                                          onChange(e.target.checked)
                                        }
                                        {...m3}
                                      />
                                      <Label check for="m3">
                                        m³
                                      </Label>
                                    </FormGroup>
                                    <FormGroup
                                      check
                                      inline
                                      className="form-check-alt form-check-lg !mb-0"
                                    >
                                      <Input
                                        id="metricTons"
                                        data-test="metricTons"
                                        type="checkbox"
                                        disabled
                                        onChange={(e) =>
                                          onChange(!e.target.checked)
                                        }
                                        {...metricTons}
                                      />
                                      <Label check for="metricTons">
                                        Metric tons
                                      </Label>
                                    </FormGroup>
                                  </div>
                                );
                              }}
                              control={control}
                              name="product_qty_uom"
                              defaultValue=""
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="product_energy_content"
                              className="fw-normal mb-4"
                            >
                              Energy content (MJ)
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="number"
                              id="product_energy_content"
                              data-test="product_energy_content"
                              {...computeProps("product_energy_content")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="productDensity"
                              className="fw-normal mb-4"
                            >
                              Product density, if available at 15° (kg/m³)
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="number"
                              id="productDensity"
                              data-test="productDensity"
                              {...computeProps("productDensity")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="eu_red_compliant_flag"
                              className="fw-normal mb-4"
                            >
                              EU RED Compliant material
                            </Label>
                            <Controller
                              render={({field: {onChange, value, ref}}) => {
                                const Yes = {checked: value === true};
                                const No = {checked: value === false};
                                return (
                                  <div>
                                    <FormGroup
                                      check
                                      inline
                                      className="form-check-alt form-check-lg !mb-0"
                                    >
                                      <Input
                                        innerRef={ref}
                                        id="EuYes"
                                        type="checkbox"
                                        data-test="EuYes"
                                        disabled
                                        onChange={(e) =>
                                          onChange(e.target.checked)
                                        }
                                        {...Yes}
                                      />
                                      <Label check for="EuYes">
                                        Yes
                                      </Label>
                                    </FormGroup>
                                    <FormGroup
                                      check
                                      inline
                                      className="form-check-alt form-check-lg !mb-0"
                                    >
                                      <Input
                                        id="EuNo"
                                        data-test="EuNo"
                                        type="checkbox"
                                        disabled
                                        onChange={(e) =>
                                          onChange(!e.target.checked)
                                        }
                                        {...No}
                                      />
                                      <Label check for="EuNo">
                                        No
                                      </Label>
                                    </FormGroup>
                                  </div>
                                );
                              }}
                              control={control}
                              name="eu_red_compliant_flag"
                              defaultValue=""
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="chain_of_custody_option"
                              className="fw-normal mb-4"
                            >
                              Chain of custody option (voluntary)
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="select"
                              id="chain_of_custody_option"
                              data-test="chain_of_custody_option"
                              {...computeProps("chain_of_custody_option")}
                              disabled

                              // placeholder="Please select"
                            >
                              <option value="">Please select</option>
                              {chainOfCustody.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader
                        targetId="certification"
                        className="[&>button]:pl-0 [&>button]:pr-0"
                      >
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100">
                          Scope of certification of raw material
                        </h5>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="certification"
                        className="[&>div]:pl-0 [&>div]:pr-0"
                      >
                        <div className="flex flex-col gap-5 pb-8">
                          <FormGroup className="my-5">
                            <Label
                              for="material_sustainability_criteria_flag"
                              className="fw-normal mb-4"
                            >
                              The raw material complies with the relevant
                              sustainability criteria according to Art. 29 (2) -
                              (7) RED II
                            </Label>
                            <CheckboxFlag
                              control={control}
                              name="material_sustainability_criteria_flag"
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="raw_material_waste_or_residue_flag"
                              className="fw-normal mb-4"
                            >
                              The raw material meets the definition of waste or
                              residue according to the RED II
                            </Label>
                            <CheckboxFlag
                              control={control}
                              name="raw_material_waste_or_residue_flag"
                              disabled
                            />
                          </FormGroup>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader
                        targetId="emission"
                        className="[&>button]:pl-0 [&>button]:pr-0"
                      >
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100">
                          Greenhouse gas (GHG) emission information
                        </h5>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="emission"
                        className="[&>div]:pl-0 [&>div]:pr-0"
                      >
                        <div className="flex flex-col gap-5 pb-8">
                          <FormGroup className="my-5">
                            <Label
                              for="default_value_applied_flag"
                              className="fw-normal mb-4"
                            >
                              Total default value according to RED II applied
                            </Label>
                            <CheckboxFlag
                              control={control}
                              name="default_value_applied_flag"
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label for="ghg_total" className="fw-normal mb-4">
                              Total GHG emissions (gCO2eq/MJ)
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="text"
                              id="ghg_total"
                              data-test="ghg_total"
                              {...computeProps("ghg_total")}
                              disabled
                            />
                          </FormGroup>
                          <div className="flex flex-row justify-between items-start gap-3 my-5">
                            <FormGroup>
                              <Label for="ghg_eec" className="fw-normal">
                                Eec
                              </Label>
                              <Input
                                className="disabled-field !text-[#111111]/50"
                                type="number"
                                id="ghg_eec"
                                data-test="ghg_eec"
                                {...computeProps("ghg_eec")}
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_el" className="fw-normal">
                                + El
                              </Label>
                              <Input
                                className="disabled-field !text-[#111111]/50"
                                type="number"
                                id="ghg_el"
                                data-test="ghg_el"
                                {...computeProps("ghg_el")}
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_ep" className="fw-normal">
                                + Ep
                              </Label>
                              <Input
                                className="disabled-field !text-[#111111]/50"
                                type="number"
                                id="ghg_ep"
                                data-test="ghg_ep"
                                {...computeProps("ghg_ep")}
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_etd" className="fw-normal">
                                + Etd
                              </Label>
                              <Input
                                className="disabled-field !text-[#111111]/50"
                                type="text"
                                id="ghg_etd"
                                data-test="ghg_etd"
                                {...computeProps("ghg_etd")}
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_eu" className="fw-normal">
                                + Eu 7
                              </Label>
                              <Input
                                className="disabled-field !text-[#111111]/50"
                                type="number"
                                id="ghg_eu"
                                data-test="ghg_eu"
                                {...computeProps("ghg_eu")}
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_esca" className="fw-normal">
                                - Esca
                              </Label>
                              <Input
                                className="disabled-field !text-[#111111]/50"
                                type="number"
                                id="ghg_esca"
                                data-test="ghg_esca"
                                {...computeProps("ghg_esca")}
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_eccs" className="fw-normal">
                                - Eccs
                              </Label>
                              <Input
                                className="disabled-field !text-[#111111]/50"
                                type="number"
                                id="ghg_eccs"
                                data-test="ghg_eccs"
                                {...computeProps("ghg_eccs")}
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_eccr" className="fw-normal">
                                - Eccr
                              </Label>
                              <Input
                                className="disabled-field !text-[#111111]/50"
                                type="number"
                                id="ghg_eccr"
                                data-test="ghg_eccr"
                                {...computeProps("ghg_eccr")}
                                disabled
                              />
                            </FormGroup>
                          </div>
                          <FormGroup className="my-5">
                            <Label
                              for="allocated_heat"
                              className="fw-normal mb-4"
                            >
                              Allocated heat (gCO2eq/MJ heat)
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="text"
                              id="allocated_heat"
                              data-test="allocated_heat"
                              {...computeProps("allocated_heat")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="allocated_electricity"
                              className="fw-normal mb-4"
                            >
                              Allocated electricity (gCO2eq/MJ electricity)
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="text"
                              id="allocated_electricity"
                              data-test="allocated_electricity"
                              {...computeProps("allocated_electricity")}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup className="my-5">
                            <Label
                              for="ghg_emission_percentage_in"
                              className="fw-normal mb-4"
                            >
                              GHG emission saving (%)
                            </Label>
                            <Input
                              className="disabled-field !text-[#111111]/50"
                              type="text"
                              id="ghg_emission_percentage_in"
                              data-test="ghg_emission_percentage_in"
                              {...computeProps("ghg_emission_percentage_in")}
                              disabled
                            />
                          </FormGroup>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                  <div className="flex flex-row justify-end items-center gap-4">
                    <Button
                      color="tertiary"
                      className="show"
                      style={{
                        borderRadius: "0px",
                        borderColor: "#cccccc",
                        color: "rgba(17, 17, 17, 0.5)",
                      }}
                      type="button"
                      onClick={() => navigate(redirectUrl)}
                      disabled={updateCertificateLoading}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="standard-primary btn-bg-br-black"
                      style={{
                        borderRadius: "0px",
                        backgroundColor: "#232323",
                      }}
                      type="button"
                      disabled={!document || updateCertificateLoading}
                      onClick={handleGenerateCertificate}
                    >
                      {updateCertificateLoading ? (
                        <Spinner size="sm" className="btn-icon-prefix" />
                      ) : (
                        <Check24 className="btn-icon-prefix" />
                      )}
                      Generate certificate
                    </Button>
                  </div>
                </Form>
              </FormProvider>
            </div>
          </Layout>
        </div>
        <SingleDocumentViewer document={{downloadUrl}} isOutgoing />
      </div>
      {showResponseModal && (
        <ResponseModal
          showModal={showResponseModal}
          header={responseHeader}
          footerArr={responseFooter}
        />
      )}
    </div>
  );
};

export default MandateCertificateGeneratePOC;
