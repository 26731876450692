import {useQuery} from "@apollo/client";
import {Filter24} from "@bphxd/ds-core-react/lib/icons";
import ALLOCATION_OUTGOING_FETCH_API from "graphql/allocation/bioLcGetAllocationSalesApi";
import MASS_BALANCE_TOP_FILTERS from "graphql/MassBalance/TopFilters";
import {findIndex, isEmpty, startCase, toLower} from "lodash";
import Layout from "modules/GlobalMassBalance/components/Layout";
import MassBalanceFilterContent from "modules/GlobalMassBalance/MassBalanceFilterContent";
import {useAppSetting} from "providers/appSetting";
import {useUserSettings} from "providers/userSettings";
import React, {useEffect, useMemo, useState} from "react";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Button} from "reactstrap";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting";

import outboundTypes from "constants/allocation";
import {NUMBER_OF_DECIMALS} from "constants/common";
import {AllocationAPI} from "graphql/allocation/bioLcAllocation";
import ALLOCATION_INCOMING_FETCH_API from "graphql/allocation/bioLcGetAvailableIncomingRecordsApi";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";
import {getFormattedNumberWithCheck} from "utils/numberUtil";
import AllocateModal from "../components/AllocateModal";
import DocumentTable from "../components/AllocationTable/DocumentTable";
import CustomizeTable from "../components/CustomizeTable";
import ResponseModal from "../components/ResponseModal";
import getColumns from "../utils";

const Allocation = () => {
  const {country, division, type, location, period, balance} = useParams();

  const [searchParams] = useSearchParams();
  const selectedId = searchParams.get("selectedId");

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const {appSetting} = useAppSetting();
  setCountryDetails(country);

  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);

  const divisionCode = division.toUpperCase();
  const divisionData = getDivisionData(divisionCode);

  const [showFilter, setShowFilter] = useState(false);
  const [fetchApiFailed, setFetchApiFailed] = useState(false);

  const [columnFilter, setColumnFilter] = useState([]);

  const [incomingRowSelection, setIncomingRowSelection] = useState({});
  const [outgoingRowSelection, setOutgoingRowSelection] = useState({});

  const [totalSustainableIncoming, setTotalSustainableIncoming] = useState(0);
  const [totalSustainableOutgoing, setTotalSustainableOugoing] = useState(0);
  const [totalOutgoingFuel, setTotalOutgoingFuel] = useState(0);

  const [filteredIncomingRows, setFilteredIncomingRows] = useState([]);
  const [filteredOutGoingRows, setFilteredOutGoingRows] = useState([]);

  const [filterDefaultValue, setFilterDefaultValues] = useState({});

  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseHeader, setResponseHeader] = useState("");
  const [responseBody, setResponseBody] = useState("");
  const [responseFooter, setResponseFooter] = useState([]);

  const [showAllocateModal, setShowAllocateModal] = useState(false);
  const [selectedOutboundType, setSelectedOutboundType] = useState("");

  const [mutationLoading, setMutationLoading] = useState(false);

  const navigate = useNavigate();

  const redirectUrl = appendParamsToUrl("/mass-balance", [
    country,
    divisionCode,
    type,
    location,
    period,
    balance,
  ]);

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: divisionCode, link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },
    {text: "Mass balance", link: redirectUrl},
    {text: "Allocation", link: "/allocation"},
  ];

  const {data: mb_period_instances, loading: topFiltersLoading} = useQuery(
    MASS_BALANCE_TOP_FILTERS,
    {
      fetchPolicy: "network-only",
      variables: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
      },
      skip:
        siteReferenceData?.siteReferenceId === undefined ||
        divisionData?.divisionId === undefined,
    },
  );

  const filterDynamicData = useMemo(
    () => mb_period_instances?.bioLcGetMBFilterOptionsApi?.mbPeriodInstances,
    [mb_period_instances?.bioLcGetMBFilterOptionsApi?.mbPeriodInstances],
  );
  const firstSelectionItem = useMemo(() => {
    const index = findIndex(filterDynamicData ?? [], (item) => {
      return (
        toLower(item?.mbBalanceType?.mbBalanceTypeCode) === toLower(type) &&
        toLower(item?.mbLocationGroup?.mbLocationGroupName) ===
          toLower(location) &&
        toLower(item?.mbPeriod?.mbPeriodName) === toLower(period) &&
        toLower(item?.mbBalanceGroup?.mbBalanceGroupName) === toLower(balance)
      );
    });
    return index === -1 ? filterDynamicData?.[0] : filterDynamicData?.[index];
  }, [balance, filterDynamicData, location, period, type]);

  useEffect(() => {
    if (!isEmpty(firstSelectionItem)) {
      setFilterDefaultValues((defaultValuesItem) => {
        return {
          ...defaultValuesItem,
          mbBalanceTypeId: firstSelectionItem?.mbBalanceType?.mbBalanceTypeId,
          mbLocationGroupId:
            firstSelectionItem?.mbLocationGroup?.mbLocationGroupId,
          mbPeriodId: firstSelectionItem?.mbPeriod?.mbPeriodId,
          mbBalanceGroupId:
            firstSelectionItem?.mbBalanceGroup?.mbBalanceGroupId,
        };
      });
    }
  }, [firstSelectionItem]);

  // Get the incoming and outgoing columns based on the country, division, date format, and decimal format
  const {INCOMING_COLUMNS: incomingCols, OUTGOING_COLUMNS: outGoingCols} =
    useMemo(
      () =>
        getColumns(
          country,
          division,
          dateFormat,
          decimalFormat,
          siteReferenceData?.siteReferenceId,
          divisionData?.divisionId,
        ),
      [
        country,
        dateFormat,
        decimalFormat,
        division,
        divisionData?.divisionId,
        siteReferenceData?.siteReferenceId,
      ],
    );

  // Set the initial state of incoming and outgoing columns
  const [incomingColumns, setIncomingColumns] = useState(incomingCols);
  const [outGoingColumns, setoutGoingColumns] = useState(outGoingCols);

  // Update the incoming and outgoing columns when the incomingCols or outGoingCols change
  useEffect(() => {
    if (incomingCols) {
      setIncomingColumns(incomingCols);
    }
    if (outGoingCols) {
      setoutGoingColumns(outGoingCols);
    }
  }, [incomingCols, outGoingCols, dateFormat]);

  // Filter the incoming columns to only include visible columns
  const filteredIncomingColumns = useMemo(() => {
    return [
      {
        header: incomingColumns?.[0]?.header,
        columns: incomingColumns?.[0]?.columns.filter(({visible}) => visible),
      },
    ];
  }, [incomingColumns]);

  // Filter the outgoing columns to only include visible columns
  const filteredoutGoingColumns = useMemo(() => {
    return [
      {
        header: outGoingColumns?.[0]?.header,
        columns: outGoingColumns?.[0]?.columns.filter(({visible}) => visible),
      },
    ];
  }, [outGoingColumns]);

  useEffect(() => {
    const calculateSum = (
      data,
      rowSelected,
      field,
      filterField = null,
      filterValue = null,
    ) => {
      if (data.length > 0) {
        const isSelected = Object.keys(rowSelected).length > 0;
        let selectedRecords = isSelected
          ? Object.keys(rowSelected).map((item) =>
              data.find((ele) => ele.id === item),
            )
          : data;

        if (filterField && filterValue !== null) {
          selectedRecords = selectedRecords.filter(
            (item) => item?.original[filterField] === filterValue,
          );
        }

        return selectedRecords.reduce(
          (sum, item) => sum + parseFloat(item?.original[field]),
          0,
        );
      }
      return 0;
    };

    const sumIncoming = calculateSum(
      filteredIncomingRows,
      incomingRowSelection,
      "sustainableQuantityM3",
    );
    const sumOutgoing = calculateSum(
      filteredOutGoingRows,
      outgoingRowSelection,
      "sustainableQtyM3",
      "outboundType",
      outboundTypes.VOLUNTARY,
    );
    const fuelTotal = calculateSum(
      filteredOutGoingRows,
      outgoingRowSelection,
      "fuelQtyM3",
    );

    setTotalSustainableIncoming(sumIncoming);
    setTotalSustainableOugoing(sumOutgoing);
    setTotalOutgoingFuel(fuelTotal);
  }, [
    incomingRowSelection,
    setIncomingRowSelection,
    filteredIncomingRows,
    filteredOutGoingRows,
    outgoingRowSelection,
  ]);

  useEffect(() => {
    if (outgoingRowSelection) {
      const data = Object.keys(outgoingRowSelection).map((item) =>
        filteredOutGoingRows.find((ele) => ele.id === item),
      );
      setSelectedOutboundType(data?.[0]?.original?.outboundType);
    }
  }, [filteredOutGoingRows, outgoingRowSelection]);

  const isDependentDataNotAvailable =
    siteReferenceData?.siteReferenceId === undefined ||
    divisionData?.divisionId === undefined ||
    !filterDefaultValue?.mbBalanceTypeId ||
    !filterDefaultValue?.mbBalanceTypeId ||
    !filterDefaultValue?.mbLocationGroupId ||
    !filterDefaultValue?.mbPeriodId;

  const {
    data: incomingData,
    loading: incomingLoading,
    refetch: incomingRefetch,
  } = useQuery(ALLOCATION_INCOMING_FETCH_API, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
      mbBalanceTypeId: filterDefaultValue?.mbBalanceTypeId,
      mbLocationGroupId: filterDefaultValue?.mbLocationGroupId,
      mbPeriodStatusId: filterDefaultValue?.mbPeriodId,
      mbBalanceGroupId: filterDefaultValue?.mbBalanceGroupId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined ||
      !filterDefaultValue?.mbBalanceTypeId ||
      !filterDefaultValue?.mbBalanceTypeId ||
      !filterDefaultValue?.mbLocationGroupId ||
      !filterDefaultValue?.mbPeriodId,
  });

  const {
    data: outgoingData,
    loading: outgoingLoading,
    refetch: outgoingRefetch,
  } = useQuery(ALLOCATION_OUTGOING_FETCH_API, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
      mbBalanceTypeId: filterDefaultValue?.mbBalanceTypeId,
      mbLocationGroupId: filterDefaultValue?.mbLocationGroupId,
      mbPeriodStatusId: filterDefaultValue?.mbPeriodId,
      mbBalanceGroupId: filterDefaultValue?.mbBalanceGroupId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined ||
      !filterDefaultValue?.mbBalanceTypeId ||
      !filterDefaultValue?.mbBalanceTypeId ||
      !filterDefaultValue?.mbLocationGroupId ||
      !filterDefaultValue?.mbPeriodId,
  });

  const incomingTableData = useMemo(() => {
    return incomingData?.bioLcGetAvailableIncomingRecordsApi?.documents;
  }, [incomingData]);

  const outGoingTableData = useMemo(() => {
    return outgoingData?.bioLcGetAllocationSalesApi?.documents;
  }, [outgoingData]);

  useEffect(() => {
    if (incomingTableData && selectedId) {
      for (let index = 0; index < incomingTableData.length; index += 1) {
        const item = incomingTableData[index];
        if (item.mbInboundQtyEuId === selectedId) {
          setIncomingRowSelection({[index]: true});
          break;
        }
      }
    }
  }, [incomingTableData, selectedId]);

  const noData =
    (incomingTableData?.length === 0 && outGoingTableData?.length === 0) ||
    fetchApiFailed;
  const moreData =
    filteredIncomingRows?.length > 14 || filteredOutGoingRows?.length > 14;

  const isIncomingSelected = Object.keys(incomingRowSelection).length > 0;
  const isPurchaseSelected = Object.keys(outgoingRowSelection).length > 0;

  const handleAllocationError = () => {
    setResponseHeader("Outgoing transactions cannot be allocated");
    setResponseBody(
      "Please select the outgoing quantity to be the same as or less than the selected purchase quantity.",
    );
    setResponseFooter([
      {
        id: 1,
        text: "Try again",
        buttonClassName: "!text-[#111]",
        action: () => {
          setIncomingRowSelection({});
          setOutgoingRowSelection({});
          setShowResponseModal(false);
        },
      },
      {
        id: 2,
        text: "Mass balance",
        buttonClassName: "!text-[#111111a3]",
        action: () => {
          navigate(redirectUrl);
        },
      },
    ]);
    setShowAllocateModal(false);
    setShowResponseModal(true);
  };

  const handleAllocationLocationError = () => {
    setResponseHeader("Outgoing transactions cannot be allocated");
    setResponseBody(
      <>
        The mass balance location you have selected for transferring to is
        currently closed. You will need to re-open{" "}
        <u>the receiving location&apos;s mass balance</u> before allocating.
      </>,
    );
    setResponseFooter([
      {
        id: 1,
        text: "Try again",
        buttonClassName: "!text-[#111]",
        action: () => {
          setIncomingRowSelection({});
          setOutgoingRowSelection({});
          setShowResponseModal(false);
        },
      },
      {
        id: 2,
        text: "Mass balance",
        buttonClassName: "!text-[#111111a3]",
        action: () => {
          navigate(redirectUrl);
        },
      },
    ]);
    setShowAllocateModal(false);
    setShowResponseModal(true);
  };

  const handleSuccessResponse = () => {
    setResponseHeader("Successfully allocated");
    setResponseBody("Successfully allocated purchase to outgoing transaction.");
    setResponseFooter([
      {
        id: 1,
        text: "Allocate another",
        buttonClassName: "!text-[#111]",
        action: () => {
          setIncomingRowSelection({});
          setOutgoingRowSelection({});
          setShowResponseModal(false);
        },
      },
      {
        id: 2,
        text: "Mass balance",
        buttonClassName: "!text-[#111111a3]",
        action: () => {
          navigate(redirectUrl);
        },
      },
    ]);
    setIncomingRowSelection({});
    setOutgoingRowSelection({});
    setShowAllocateModal(false);
    setShowResponseModal(true);
  };

  const handleAllocate = () => {
    if (
      selectedOutboundType === outboundTypes.VOLUNTARY &&
      totalSustainableIncoming < totalSustainableOutgoing
    ) {
      handleAllocationError();
    } else {
      setShowAllocateModal(true);
    }
  };

  const handleLinkage = async (data) => {
    setMutationLoading(true);

    const inboundIds = Object.keys(incomingRowSelection)
      .map((item) => filteredIncomingRows.find((ele) => ele.id === item))
      .map((selectedItem) => selectedItem.original.mbInboundQtyEuId);

    const outboundData = Object.keys(outgoingRowSelection)
      .map((item) => filteredOutGoingRows.find((ele) => ele.id === item))
      .map((selectedItem) => selectedItem.original);

    const allocationData = {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
      mbBalanceTypeId: filterDefaultValue?.mbBalanceTypeId,
      mbLocationGroupId: filterDefaultValue?.mbLocationGroupId,
      mbPeriodId: filterDefaultValue?.mbPeriodId,
      mbBalanceGroupId: filterDefaultValue?.mbBalanceGroupId,
      mbInboundEuIdsList: inboundIds,
      outMovementType: selectedOutboundType,
      quantityAllocatedM3: data?.sustainableQtyM3,
      remarks: data?.notes,
      mbOutgoingFieldsList: outboundData.map((item) => ({
        deliveryDate: item.deliveryDate,
        fuelQtyM3: parseFloat(item.fuelQtyM3),
        sustainableQtyM3: parseFloat(item.sustainableQtyM3),
        outboundType: item.outboundType,
        recipient: item.recipient,
        shipToLocation: item.shipToLocation,
      })),
    };

    const {data: responseData} = await AllocationAPI({
      event: allocationData,
    });

    if (responseData?.bioLcAllocation?.statusCode === 200) {
      handleSuccessResponse();
    } else if (selectedOutboundType === outboundTypes.LOCATION_TRANSFER) {
      handleAllocationLocationError();
    } else {
      handleAllocationError();
    }
    setMutationLoading(false);
  };

  const inLoading =
    incomingLoading || topFiltersLoading || isDependentDataNotAvailable;

  const outLoading =
    outgoingLoading || topFiltersLoading || isDependentDataNotAvailable;

  return (
    <Layout title="Allocation" breadcrumbs={breadcrumbItems}>
      <div className="flex flex-col justify-stretch w-full items-start px-7 pt-[32px]">
        <div className=" flex flex-row justify-between w-full mb-[24px]">
          <div className="flex flex-col justify-stretch w-full items-start">
            <MassBalanceFilterContent
              defaultValues={filterDefaultValue}
              filterDynamicData={filterDynamicData}
              allFiltersDisabled
            ></MassBalanceFilterContent>
          </div>
          <div className="flex flex-none flex-row ml-5 mb-[8px] items-end">
            <Button
              data-test="allocate-outgoing-button"
              color="standard-tertiary rounded-0"
              type="button"
              onClick={() => navigate(redirectUrl)}
            >
              Back to mass balance
            </Button>
          </div>
        </div>
        <div className="mb-6">
          <span className="text-xl font-light">
            Allocate outgoing transactions
          </span>
        </div>
        <div className=" flex flex-row justify-between w-full p-4 bg-white">
          <div>
            <CustomizeTable
              columns={incomingColumns}
              onColumnChange={(cols) => setIncomingColumns(cols)}
              purchaseColumns={outGoingColumns}
              onPurchaseChange={(cols) => setoutGoingColumns(cols)}
              showApplyButton={false}
            />
          </div>
          <div className="flex flex-none flex-row items-center">
            {columnFilter.length > 0 && (
              <div className="mr-2">
                <Button
                  data-test="copro-filter-btn"
                  color="standard-tertiary rounded-0"
                  onClick={() => setColumnFilter([])}
                >
                  Clear
                </Button>
              </div>
            )}
            <div>
              <Button
                className={showFilter ? "border-black" : ""}
                data-test="copro-filter-btn"
                color="standard-tertiary rounded-0"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filters
                <Filter24 className="btn-icon-suffix" />
              </Button>
            </div>
          </div>
        </div>
        <div
          className={`flex border-t-[1px] border-[#ededed] w-full overflow-y-auto overflow-x-hidden ${
            noData ? "" : "h-[calc(100vh-640px)]"
          }`}
        >
          <div className="min-w-[50%] bg-white">
            <div className={`${noData ? "" : "overflow-x-auto"}`}>
              <DocumentTable
                columns={filteredIncomingColumns}
                data={fetchApiFailed ? [] : incomingTableData || []}
                className="incoming-docs-table"
                loading={inLoading}
                columnFilter={columnFilter}
                setColumnFilter={setColumnFilter}
                type="Incoming"
                enableRowSelection
                rowSelection={incomingRowSelection}
                setRowSelection={setIncomingRowSelection}
                otherData={fetchApiFailed ? [] : outGoingTableData || []}
                setFilteredIncomingRows={setFilteredIncomingRows}
                showFilter={showFilter}
              />
            </div>
          </div>
          <div
            style={{
              width: "2px",
              margin: "0 0.3px 0 0.7px",
              border: "solid 3px #fafafa",
            }}
          ></div>
          <div className="min-w-[50%] bg-white">
            <div className={`${noData ? "" : "overflow-x-auto"}`}>
              <DocumentTable
                columns={filteredoutGoingColumns}
                data={fetchApiFailed ? [] : outGoingTableData || []}
                className="incoming-docs-table"
                loading={outLoading}
                columnFilter={columnFilter}
                setColumnFilter={setColumnFilter}
                type="Outgoing"
                enableRowSelection
                rowSelection={outgoingRowSelection}
                setRowSelection={setOutgoingRowSelection}
                otherData={fetchApiFailed ? [] : incomingTableData || []}
                setFilteredOutGoingRows={setFilteredOutGoingRows}
                showFilter={showFilter}
              />
            </div>
          </div>
        </div>
        {noData && !fetchApiFailed && (
          <div className="w-full h-[calc(100vh-725px)] flex flex-col items-center mt-[-15px] text-center bg-white overflow-y-auto">
            <p className="mt-10 mb-[30px] w-[400px]">
              It appears that you have allocated all available outgoing
              transactions with purchases.
            </p>
            <p className="w-[400px]">
              Back to{" "}
              <Link to={redirectUrl} className="!underline text-black">
                mass balance
              </Link>{" "}
            </p>
          </div>
        )}
        {fetchApiFailed && (
          <div className="w-full h-[calc(100vh-725px)] flex flex-col items-center mt-[-15px] text-center bg-white overflow-y-auto">
            <p className="mt-10 mb-[30px] w-[400px]">
              Something went wrong. We&apos;re experiencing technical
              difficulties. Please try again later. If the issue persists,
              contact our support team for assistance.
            </p>
            <p className="w-[400px]">
              Back to{" "}
              <Link to={redirectUrl} className="!underline text-black">
                mass balance
              </Link>{" "}
            </p>
          </div>
        )}
        <div
          className={`w-full flex items-center h-16 bg-white z-10 ${
            moreData
              ? "[box-shadow:0_-6px_10px_0_rgba(17,_17,_17,_0.08)]"
              : "mt-[3px]"
          }`}
        >
          <div className="w-1/2 text-end">
            {filteredIncomingRows.length > 0 && !fetchApiFailed && (
              <>
                <span className="text-gray-700 mr-[10px]">
                  {Object.keys(incomingRowSelection).length > 0
                    ? "Selected sustainable quantity"
                    : "Sustainable quantity"}
                </span>
                <span className="mr-[15px]">
                  {getFormattedNumberWithCheck(
                    totalSustainableIncoming,
                    NUMBER_OF_DECIMALS,
                  )}{" "}
                  m³
                </span>
              </>
            )}
          </div>
          <div className="w-1/2 text-end">
            {filteredOutGoingRows.length > 0 && !fetchApiFailed && (
              <>
                <span className="mr-10">
                  <span className="text-gray-700 mr-[10px]">
                    {Object.keys(outgoingRowSelection).length > 0
                      ? "Selected sustainable quantity"
                      : "Sustainable quantity"}
                  </span>
                  <span>
                    {getFormattedNumberWithCheck(
                      totalSustainableOutgoing,
                      NUMBER_OF_DECIMALS,
                    )}{" "}
                    m³
                  </span>
                </span>
                <span className="text-gray-700 mr-[10px]">
                  {Object.keys(outgoingRowSelection).length > 0
                    ? "Selected fuel quantity"
                    : "Fuel quantity"}
                </span>
                <span className="mr-[15px]">
                  {getFormattedNumberWithCheck(
                    totalOutgoingFuel,
                    NUMBER_OF_DECIMALS,
                  )}{" "}
                  m³
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-stretch w-full items-start px-7 mt-6">
        <div className=" flex flex-row justify-end w-full mb-5">
          <Button
            color="tertiary"
            outline
            type="button"
            size="md"
            className="show link-btn rounded-0"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="secondary"
            className="float-right rounded-0 text-gray-400"
            size="md"
            disabled={!isIncomingSelected || !isPurchaseSelected}
            onClick={handleAllocate}
          >
            Allocate
          </Button>
        </div>
      </div>
      {showResponseModal && (
        <ResponseModal
          showModal={showResponseModal}
          header={responseHeader}
          body={responseBody}
          footerArr={responseFooter}
        />
      )}

      {showAllocateModal && (
        <AllocateModal
          showModal={showAllocateModal}
          setShowModal={setShowAllocateModal}
          outboundType={selectedOutboundType}
          sustainableQty={totalSustainableIncoming}
          fuelQty={totalOutgoingFuel}
          unitOfMeasure="m³"
          onSubmit={handleLinkage}
          loading={mutationLoading}
        />
      )}
    </Layout>
  );
};
export default Allocation;
