import {useQuery} from "@apollo/client";
import {Download24, Filter24} from "@bphxd/ds-core-react/lib/icons";
import moment from "moment";
import Papa from "papaparse";
import React, {useEffect, useMemo, useState} from "react";

import * as XLSX from "xlsx";

import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";

import PropTypes from "prop-types";

import {RULE_VERSION_TEST} from "graphql/compliance-rules-engine/sandboxTestRuleVersion";

import {useUserSettings} from "providers/userSettings";
import {useParams} from "react-router-dom";
import {setCountryModuleDetails} from "utils/helpers/getAppSetting.js";
import ResultsTable from "./ResultsTable";

import "./styles.scss";

export const exportExcelData = (data, exportData) => {
  // Format data using the provided headerMapping
  const ws = XLSX.utils.json_to_sheet(exportData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Results");
  XLSX.writeFile(wb, "results_export.xlsx");
};

const ExecutionResultsPage = ({
  ruleName,
  ruleVersionId,
  indexForExecute,
  selectedTemplateItems,
}) => {
  const [rowSelection, setRowSelection] = useState({});
  const {country: countryName} = useParams();

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  setCountryModuleDetails(countryName, "complianceRulesEngine");

  const [filtering, setFiltering] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [columnFilter, setColumnFilter] = useState([]);
  const [columns, setColumns] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [csvLoading, setCsvLoading] = useState(true);

  const blockConfigs = selectedTemplateItems?.map((item) => {
    const configuration = item?.configuration;
    return {
      ruleBlockMasterId: item?.ruleBlockMasterId,
      index: item?.templateCount,
      configuration: configuration ? JSON.stringify(configuration) : {},
    };
  });

  const requestParams =
    indexForExecute === 0
      ? {ruleVersionId}
      : {
          ruleVersionId,
          index: indexForExecute,
          blockConfigs: blockConfigs?.slice(0, indexForExecute),
        };

  const {data, loading} = useQuery(RULE_VERSION_TEST, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      event: {
        ...requestParams,
      },
    },
  });

  const downloadLink =
    data?.bioLcComplianceRulesEngineTestExecute?.executionResultDownloadLink;

  const schema = data?.bioLcComplianceRulesEngineTestExecute?.schema;

  useEffect(() => {
    // Ensure Papa.parse is called unconditionally at the top level of useEffect
    if (downloadLink && sortedData?.length === 0) {
      Papa.parse(downloadLink, {
        download: true,
        complete: (results) => {
          const dataArray = [];
          const keys = results?.data[0];

          results?.data?.slice(1).forEach((line) => {
            if (line.length === 1 && line[0] === "") {
              return;
            }
            const d = {};
            line.forEach((value, i) => {
              d[keys[i]] = value;
            });
            dataArray.push(d);
          });

          setSortedData(dataArray);
          setCsvLoading(false);
        },
      });
    }
  }, [downloadLink, sortedData]);

  // added this comsole for testing in diff env
  console.log("data-schema", sortedData, schema);

  const handleExportSelected = (rowSelection) => {
    exportExcelData(rowSelection, sortedData);
  };

  const tableCols = useMemo(() => {
    if (schema) {
      return schema.map((i) => {
        const columnName = i.column;
        if (i.type === "date" || i.type === "datetime") {
          return {
            id: columnName,
            header: columnName,
            accessorKey: columnName,
            key: columnName,
            meta: {
              filterVariant: "daterange",
            },
            filterFn: "dateRangeFilterFn",

            cell: ({cell}) => {
              return (
                cell.getValue() && moment(cell.getValue()).format(dateFormat)
              );
            },
          };
        }

        return {
          id: columnName,
          header: columnName,
          accessorKey: columnName,
          key: columnName,
          meta: {
            filterVariant: "search",
          },

          cell: ({cell}) => {
            return cell.getValue();
          },
        };
      });
    }
    return [];
  }, [schema, dateFormat]);

  return (
    <>
      {loading && (
        <div className="spinner-container min-h-550px">
          <div>
            <Spinner />
          </div>
          <div>Preparing your test results...</div>
        </div>
      )}

      {!loading &&
        data &&
        data?.bioLcComplianceRulesEngineTestExecute?.statusCode !== 200 && (
          <div className="spinner-container  min-h-550px">
            <div>Something went wrong, please check the version details...</div>
          </div>
        )}

      {!loading &&
        data &&
        data?.bioLcComplianceRulesEngineTestExecute?.statusCode === 200 && (
          <div className="flex flex-col justify-stretch w-full items-start">
            <div className="results-table-buttons flex flex-row justify-between w-full">
              <div className="flex flex-row justify-between w-full my-3 gap-x-3 items-center">
                <div className="rule-engine-results-rule-name w-full text-left">
                  {ruleName}
                </div>
                <div className="flex flex-row justify-end w-full my-3 gap-x-3 items-center">
                  <div className="mr-2">
                    <Button
                      data-test="doc-manager-filter-btn"
                      color="standard-tertiary rounded-0"
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      Filters
                      <Filter24 className="btn-icon-suffix" />
                    </Button>
                  </div>
                  <div className="">
                    <div>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="standard-tertiary rounded-0"
                          caret
                        >
                          Export
                          <Download24 className="btn-icon-suffix" />
                        </DropdownToggle>
                        <DropdownMenu tag="ul">
                          <li>
                            <DropdownItem
                              href="#"
                              onClick={async () =>
                                handleExportSelected(rowSelection)
                              }
                              disabled={Object.keys(rowSelection)?.length === 0}
                            >{`Export selected (${
                              Object.keys(rowSelection)?.length
                            })`}</DropdownItem>
                          </li>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="results-table-container border-secondary w-full pb-40 min-h-[500px]">
              <ResultsTable
                columns={tableCols}
                data={sortedData ?? []}
                className="results-table-compliance-rules-engine"
                highlightedRows={[]}
                loading={csvLoading}
                filtering={filtering?.trim()}
                setFiltering={setFiltering}
                columnFilter={columnFilter}
                setColumnFilter={setColumnFilter}
                type="Rules"
                showFilter={showFilter}
                enableRowSelection
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                pageIndex={0}
              />
            </div>
          </div>
        )}
    </>
  );
};

ExecutionResultsPage.propTypes = {
  ruleName: PropTypes.string,
  ruleVersionId: PropTypes.string,
  indexForExecute: PropTypes.number,
  selectedTemplateItems: PropTypes.array,
};

export default ExecutionResultsPage;
