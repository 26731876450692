import {
  Edit24,
  TailDown24,
  TailUp24,
  Trash24,
  TriangleRight24,
} from "@bphxd/ds-core-react/lib/icons";
import LineArrow from "assets/images/line_arrow.png";
import PropTypes from "prop-types";
import {useState} from "react";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import {blockIconList} from "../../content/Blocks";
import "../index.css";

const ButtonInDroppable = ({
  index,
  item,
  handleTemplate,
  selectedTemplateItems,
  moveItemDown,
  moveItemUp,
  deleteItem,
  selectedBlock,
  isDisabled,
  setExecutionResultsPopupVisible,
  ExecutionResultsPopupVisible,
  setIndexForExecute,
}) => {
  const [actionHoverVisible, setActionHoverVisible] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState(null);

  const renderHeightForDroppable = (itemsCount) => {
    switch (itemsCount) {
      case 0:
      case 1:
      case 2:
        return "h-[264px]";
      case 3:
        return "h-[176px]";
      case 4:
        return "h-[88px]";
      default:
        return "h-[88px]";
    }
  };

  return (
    <div className="flex flex-row gap-x-6">
      <div className="flex flex-col items-center ">
        <div className="flex flex-row items-center ">
          <button
            className={
              item?.id === selectedBlock?.id &&
              item?.templateCount === selectedBlock?.templateCount
                ? "droppable-section-button-selected"
                : "droppable-section-button"
            }
            id={`${item?.id}-${item?.templateCount}`}
            type="button"
            onFocus={() => {
              setActionHoverVisible(true);
              handleTemplate(true, item);
            }}
          >
            {blockIconList[item.id]}
            <div className="mt-3.5">
              <span className="font-[500]">{item.blockName}</span>
              <p className="text-gray-900">{item.configuration.description}</p>
            </div>
          </button>
        </div>

        {(selectedTemplateItems?.length ?? 0) - 1 !== index && (
          <div>
            <img className="h-[75px]" src={LineArrow} alt="down" />
          </div>
        )}
        {(selectedTemplateItems?.length ?? 0) - 2 === index && (
          <>
            <div
              className={`dropping-section-view ${renderHeightForDroppable(
                selectedTemplateItems?.length ?? 0,
              )}`}
            ></div>
            <div>
              <img className="h-[75px]" src={LineArrow} alt="down" />
            </div>
          </>
        )}
      </div>

      <div className="relative">
        <div className="overlay-backdrop"></div>
        <div className="overlay-content">
          <div className="flex flex-row gap-x-3">
            {!actionHoverVisible && <div className="w-56" />}
            {actionHoverVisible &&
            item?.id === selectedBlock?.id &&
            item?.templateCount === selectedBlock?.templateCount ? (
              <>
                <button
                  className="action-hover-button"
                  id={`button-action-hover-edit-${item?.id}`}
                  type="button"
                  onClick={() => {
                    setExecutionResultsPopupVisible(
                      !ExecutionResultsPopupVisible,
                    );
                    setIndexForExecute(item?.templateCount);
                  }}
                >
                  <TriangleRight24 />
                </button>
                <button
                  className={
                    item?.disabled || isDisabled
                      ? "action-hover-button-disabled"
                      : "action-hover-button"
                  }
                  id={`button-action-hover-edit-${item?.id}`}
                  type="button"
                  onClick={() => handleTemplate(true, item)}
                  disabled={isDisabled}
                >
                  <Edit24 />
                </button>
                <button
                  className={
                    item?.disabled || index === 1 || isDisabled
                      ? "action-hover-button-disabled"
                      : "action-hover-button"
                  }
                  id={`button-action-hover-up-${item?.id}`}
                  type="button"
                  disabled={item?.disabled || index === 1 || isDisabled}
                  onClick={() => moveItemUp(index)}
                >
                  <TailUp24 />
                </button>
                <button
                  className={
                    item?.disabled ||
                    isDisabled ||
                    (selectedTemplateItems?.length ?? 0) - 2 === index
                      ? "action-hover-button-disabled"
                      : "action-hover-button"
                  }
                  id={`button-action-hover-down-${item?.id}`}
                  type="button"
                  disabled={
                    item?.disabled ||
                    isDisabled ||
                    (selectedTemplateItems?.length ?? 0) - 2 === index
                  }
                  onClick={() => moveItemDown(index)}
                >
                  <TailDown24 />
                </button>
                <button
                  className={
                    item?.disabled || isDisabled
                      ? "action-hover-button-disabled"
                      : "action-hover-button"
                  }
                  id={`button-action-hover-delete-${item?.id}`}
                  type="button"
                  disabled={item?.disabled || isDisabled}
                  onClick={() => {
                    setDeleteItemIndex(index);
                    setDeleteConfirmModal(true);
                  }}
                  onKeyDown={() => {
                    setDeleteItemIndex(index);
                    setDeleteConfirmModal(true);
                  }}
                >
                  <Trash24 />
                </button>
              </>
            ) : (
              actionHoverVisible && <div className=" w-56" />
            )}{" "}
          </div>
        </div>
      </div>
      <Modal
        size="sm"
        isOpen={deleteConfirmModal}
        className="modal-dialog-centered"
      >
        <ModalBody className="text-center">
          <p className="mb-[2px]"></p>
          <p className=" mb-0">Are you sure you want to delete this block?</p>
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 !py-[10px] opacity-80 opacity-100-hover bg-transparent"
                onClick={() => {
                  setDeleteConfirmModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 !py-[10px] bg-transparent text-default"
                onClick={() => {
                  deleteItem(deleteItemIndex);
                  setDeleteConfirmModal(false);
                }}
                onKeyDown={() => {
                  deleteItem(deleteItemIndex);
                  setDeleteConfirmModal(false);
                }}
              >
                Yes, delete
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

ButtonInDroppable.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  handleTemplate: PropTypes.func,
  selectedTemplateItems: PropTypes.array,
  moveItemUp: PropTypes.func,
  moveItemDown: PropTypes.func,
  deleteItem: PropTypes.func,
  selectedBlock: PropTypes.object,
  isDisabled: PropTypes.bool,
  ExecutionResultsPopupVisible: PropTypes.bool,
  setExecutionResultsPopupVisible: PropTypes.func,
  setIndexForExecute: PropTypes.func,
};

export default ButtonInDroppable;
