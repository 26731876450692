import tw from "twin.macro";
import PropTypes from "prop-types";
import {Alert24, Info24, Checklist24} from "@bphxd/ds-core-react/lib/icons";
import {useQuery} from "@apollo/client";
import {NavLink} from "react-router-dom";
import {COPRO_US_TASKS_API} from "graphql/coprocessing/tasks";
import {
  TASKS,
  TASKS_LABELS,
  TASKS_CARDS,
} from "modules/co-processing/constants/receipts";
import {ERRORS} from "modules/co-processing/constants/coProcessing";
import calculateDaysAgo from "modules/co-processing/helpers/dateHelper";
import {useDateRange} from "modules/co-processing/context/DateRangeContext";
import retrieveErrorType, {
  updatedRecordsFormatter,
} from "modules/co-processing/helpers/retrieveTasksData";
import TaskLoading from "modules/co-processing/components/TaskManagerLoading";
import {
  Notification,
  NotificationsList,
  NotificationsContainer,
  NotificationToggle,
} from "modules/co-processing/components/NotificationsPopover";
import getDetailsByTask from "modules/co-processing/helpers/getDetailsByTask";

let tasksApiData = null;

const TaskNotification = tw.div`flex pr-14`;
const TaskLabel = tw.span`ml-2 border-b-2`;
const TaskManager = ({task_type}) => {
  const {dateRange} = useDateRange();
  const {loading, data, refetch} = useQuery(COPRO_US_TASKS_API, {
    variables: {
      task_type,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    skip: task_type === "credit-gen",
  });

  if (loading) {
    return <TaskLoading />;
  }

  if (!loading) {
    tasksApiData = data?.bioLcTasksApi?.body?.data;
  }

  const {review, updated, failures, missingCofaIdErrors} = retrieveErrorType(
    tasksApiData,
    task_type,
  );

  const errorCountPDF = review?.items?.length;
  const updatedRecords = updatedRecordsFormatter(updated);
  const updatedRecordCount = updatedRecords?.length;
  const spreadsheetCount = failures?.items?.length;
  const missingIdsCount = missingCofaIdErrors?.length;
  const tasksCount =
    errorCountPDF + updatedRecordCount + spreadsheetCount + missingIdsCount;

  const clearUpdatedNotifications = () => {
    refetch({
      op: "update_ack",
      task_type,
      ids: updated?.items?.map((i) => i.inbound_doc_status_us_id),
    });
  };

  const clearMissingIDNotifications = () => {
    refetch({
      op: "update_ack",
      task_type,
      ids: missingCofaIdErrors?.map((i) => i.inbound_doc_status_us_id),
    });
  };

  const clearFailureNotifications = () => {
    refetch({
      op: "update_ack",
      task_type,
      ids: failures?.items?.map((i) => i.inbound_doc_status_us_id),
    });
  };

  const taskDetails = getDetailsByTask(task_type);

  return (
    <div data-test="copro-task-manager" className="mx-[40px] pb-[30px] pt-2">
      <div className="section-titles" style={{fontSize: "1.25rem"}}>
        {TASKS}
      </div>

      {tasksCount ? (
        <div className="flex">
          {!!errorCountPDF && (
            <NotificationsContainer>
              <NotificationToggle>
                <TaskNotification>
                  <Alert24 className="text-danger" />
                  <TaskLabel>{`${ERRORS.REVIEW_ERRORS} ${errorCountPDF} ${ERRORS.IMPORT_ERRORS}`}</TaskLabel>
                </TaskNotification>
              </NotificationToggle>

              <NotificationsList header="Review import errors">
                {review?.items?.map((pdfTask, index) => (
                  <Notification key={pdfTask?.inbound_doc_status_us_id}>
                    <NavLink
                      key={index}
                      data-test="review-import-errors-link"
                      to={taskDetails?.documentViewerURL}
                      state={{
                        id: pdfTask?.resource_id?.[taskDetails.idKey],
                        url: pdfTask?.view_url,
                        dateRange,
                      }}
                    >
                      <p className="small text-dark fw-normal lh-1-75 m-0">
                        {`${ERRORS.REVIEW_ERRORS} record: ${pdfTask?.doc_name}`}
                      </p>
                    </NavLink>
                  </Notification>
                ))}
              </NotificationsList>
            </NotificationsContainer>
          )}

          {!!updatedRecordCount && (
            <NotificationsContainer>
              <NotificationToggle>
                <TaskNotification>
                  <Info24 className="text-info" />
                  <TaskLabel>{`${updatedRecordCount} ${TASKS_LABELS.RECORDS_UPDATED}`}</TaskLabel>
                </TaskNotification>
                <div className="pl-8 pt-2 text-secondary fs-6">
                  {calculateDaysAgo(updated?.latestUpdate, true)}
                </div>
              </NotificationToggle>

              <NotificationsList
                header={TASKS_CARDS.VIEW_UPDATED_RECORD}
                onClearNotifications={clearUpdatedNotifications}
              >
                {updatedRecords.map((record, index) => (
                  <Notification key={index}>
                    <div className="flex items-center mb-2">
                      <p className="small text-dark fw-normal">{record}</p>
                    </div>
                  </Notification>
                ))}
              </NotificationsList>
            </NotificationsContainer>
          )}

          {!!spreadsheetCount && (
            <NotificationsContainer>
              <NotificationToggle>
                <TaskNotification id="popover-diff-example">
                  <Info24 className="text-info" />
                  <TaskLabel>{`${spreadsheetCount} ${TASKS_LABELS.SPREADSHEETS_FAILED}`}</TaskLabel>
                </TaskNotification>

                <div className="pl-8 pt-2 text-secondary fs-6">
                  {calculateDaysAgo(failures?.latestUpdate)}
                </div>
              </NotificationToggle>

              <NotificationsList
                header={TASKS_CARDS.VIEW_FAILED_SPREADSHEET}
                onClearNotifications={clearFailureNotifications}
              >
                {failures?.items?.map((record) => (
                  <Notification key={record?.inbound_doc_status_us_id}>
                    <p className="small text-dark fw-normal lh-1-75 m-0">
                      {record?.doc_name}
                    </p>
                    <small className="fw-normal ms-auto">
                      Excel has missing data and could not be added. Please
                      contact the supplier to resend the file with updated data.
                    </small>
                  </Notification>
                ))}
              </NotificationsList>
            </NotificationsContainer>
          )}

          {!!(missingIdsCount && task_type === "receipts") && (
            <NotificationsContainer>
              <NotificationToggle>
                <TaskNotification>
                  <Info24 className="text-info" />
                  <TaskLabel>{`${missingIdsCount} COA errors `}</TaskLabel>
                </TaskNotification>
              </NotificationToggle>

              <NotificationsList
                header="Missing COA ID"
                onClearNotifications={clearMissingIDNotifications}
              >
                {missingCofaIdErrors.map(({doc_name}) => (
                  <Notification key={doc_name} tag="li">
                    <div className="flex items-center mb-2">
                      <p className="small text-dark fw-normal">
                        {doc_name} is missing ID.
                      </p>
                    </div>
                  </Notification>
                ))}
              </NotificationsList>
            </NotificationsContainer>
          )}
        </div>
      ) : (
        <div data-test="copro-no-tasks" className="flex items-center">
          <Checklist24 />
          <div className="ml-2">
            <span className="font-bold">{`${TASKS_LABELS.ALL_DONE} `}</span>
            {taskDetails?.noTaskMsg}
          </div>
        </div>
      )}
    </div>
  );
};

TaskManager.propTypes = {
  task_type: PropTypes.string,
};

export default TaskManager;
