import BatchDetailDropdown from "../components/Batches/BatchDetailDropdown";
import BatchChecklistProgress from "../components/Shared/BatchChecklist/BatchChecklistProgress";
import BatchesStatusChip from "../components/Shared/BatchesStatusChip";
import StatusWithIcon from "../components/Shared/StatusWithIcon";
import {displayDate} from "./dateHelper";

export const getDisplayValue = (props) => {
  const {row, column, getValue} = props;
  const value = getValue();

  switch (column.id) {
    case "parcel_status":
    case "status":
      return <StatusWithIcon value={value} />;

    case "batch_status":
      return <BatchesStatusChip value={value} />;

    case "selected":
      return <div></div>;

    case "batch_start_time":
    case "batch_end_time":
    case "tank_certified_datez":
    case "c14_test_date": {
      const date = value ? displayDate(value) : "N/A";
      return <div>{date}</div>;
    }

    case "feedstock_qty":
    case "tank_volume":
      return <div>{value?.toLocaleString()}</div>;
    case "dropdown_icon":
      return <BatchDetailDropdown batchDetails={row} />;

    case "completion_percentage":
      return (
        <BatchChecklistProgress
          popoverId={row?.batch_id.replace(".", "-")}
          statusDetails={row?.details ?? {}}
        />
      );

    default:
      return <div>{value}</div>;
  }
};

const buildColumns = (headers) => {
  const columns = [];
  Object.keys(headers).forEach((key) => {
    return columns.push({
      id: key,
      accessorKey: key,
      key,
      header: headers[key],
      align: "left",
      enableSorting: key !== "dropdown_icon",
      cell: (props) => getDisplayValue(props),
    });
  });
  return columns;
};

export default buildColumns;

export const filterDraftColumns = (data, rows) => {
  const listOfKeys = [
    "delivered_from",
    "trade_reference",
    "leg_number",
    "feedstock_type",
    "feedstock_qty",
    "feedstock_qty_mt",
    "feedstock_qty_uom",
    "date_range",
    "bols_used",
  ];

  return Object.keys(rows)?.map((item) => {
    const dataItem = data?.[item];
    const filteredItem = {};
    listOfKeys.forEach((key) => {
      filteredItem[key] = dataItem?.[key];
    });
    return filteredItem;
  });
};
