import {useQuery} from "@apollo/client";
import {GET_OUTGOING_PDF} from "graphql/MassBalance/Actions/OutgoingCertificate";
import Layout from "modules/GlobalMassBalance/components/Layout";
import {useAppSetting} from "providers/appSetting";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting.js";

import {useAccount, useMsal} from "@azure/msal-react";
import {Email24, TailLeft24} from "@bphxd/ds-core-react/lib/icons";
import {SendCertificateEmailAPI} from "graphql/docManager/sendCertificateDetails";
import {startCase} from "lodash";
import {useCallback, useState} from "react";
import {toast} from "react-toastify";
import {Button, Spinner} from "reactstrap";

const ViewCertificatePage = () => {
  const navigate = useNavigate();
  const {appSetting} = useAppSetting();

  const {country, division, outId} = useParams();
  const divData = getDivisionData(division);
  const [updateCertificateLoading, setUpdateCertificateLoading] =
    useState(false);

  const [searchParams] = useSearchParams();
  const navigatedFromPage = searchParams.get("page") ?? "mass-balance";
  const redirectTo = searchParams.get("redirectTo");

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  setCountryDetails(country);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);

  const backButtonLabel = (() => {
    switch (navigatedFromPage) {
      case "outgoing":
        return "Outgoing documents";
      case "mandate-certificate":
        return "Mandate certificates";
      default:
        return "Mass balance";
    }
  })();

  // get version details
  const {
    data: pdfData,
    loading: pdfLoading,
    refetch,
  } = useQuery(GET_OUTGOING_PDF, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      divisionId: divData?.divisionId,
      certificateOutboundEuId: outId,
      siteReferenceId: siteReferenceData?.siteReferenceId,
    },
  });

  const handleSendCertificate = useCallback(async () => {
    setUpdateCertificateLoading(true);
    await SendCertificateEmailAPI({
      event: {
        userId: account?.username,
        fileData: [{certificateOutboundEuId: outId, mbOutboundQtyEuId: outId}],
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divData?.divisionId,
      },
    });
    refetch();
    setUpdateCertificateLoading(false);
    toast.success("Certificate sent");
  }, [
    account?.username,
    divData?.divisionId,
    outId,
    refetch,
    siteReferenceData?.siteReferenceId,
  ]);

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: divData?.divisionCode, link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },
    {text: backButtonLabel, link: redirectTo},
    {
      text: pdfData?.bioLcGetGeneratedCertificate?.certificateNumber,
    },
  ];

  const downloadUrl = pdfData?.bioLcGetGeneratedCertificate?.downloadUrl;

  let title;
  let subtitle;
  const status = pdfData?.bioLcGetGeneratedCertificate?.status;

  switch (status) {
    case "GENERATED":
      title = "Allocated";
      break;
    case "SENT":
      title = "Allocated and sent";
      break;
    case "REMOVED":
      title = "Removed";
      break;
    default:
      title = "In-progress";
      break;
  }

  switch (status) {
    case "GENERATED":
      subtitle = "This document is now ready to issue to your customer.";
      break;
    case "SENT":
      subtitle =
        "This document is sent already. You can click send to reissue to your customer.";
      break;
    case "REMOVED":
      subtitle = "This document is reverted.";
      break;
    default:
      subtitle = "This document generation is in progress.";
      break;
  }

  const isSendDisabled =
    !downloadUrl ||
    updateCertificateLoading ||
    (pdfData?.bioLcGetGeneratedCertificate?.status !== "GENERATED" &&
      pdfData?.bioLcGetGeneratedCertificate?.status !== "SENT");

  if (
    pdfLoading ||
    siteReferenceData?.siteReferenceId === undefined ||
    divData?.divisionId === undefined
  ) {
    return (
      <div
        className="flex flex-col min-h-full items-center py-12 bp-core bg-light-gray-100"
        style={{
          height: "calc(100vh - 352px)",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col view-doc__max-scroll bp-core bg-light-gray-100">
      <div className="flex flex-1 flex-row justify-between items-stretch min-h-full w-full">
        <div className="flex flex-col flex-1 max-h-screen overflow-y-auto">
          <Layout
            title={title}
            breadcrumbs={breadcrumbItems}
            subtitle={
              <div className="flex flex-col justify-between items-start gap-2 small pb-[36px] px-7 mt-[-16px]">
                <span className="mb-2 text-[16px] font-light">{subtitle}</span>
              </div>
            }
          >
            <div className="flex justify-end m-7">
              <Button
                color="tertiary"
                className="show btn-bg-br-gray mr-3"
                style={{
                  borderRadius: "0px",
                }}
                type="button"
                onClick={() => navigate(redirectTo)}
              >
                <TailLeft24 className="btn-icon-prefix" />
                Back
              </Button>
              <Button
                color="standard-primary btn-bg-br-black"
                style={{
                  borderRadius: "0px",
                }}
                type="button"
                disabled={isSendDisabled}
                onClick={() => handleSendCertificate()}
              >
                {updateCertificateLoading ? (
                  <Spinner size="sm" className="btn-icon-prefix" />
                ) : (
                  <Email24 className="btn-icon-prefix" />
                )}
                Send
              </Button>
            </div>
          </Layout>
        </div>
        <div className="flex flex-col flex-1 items-start justify-stretch bg-light-gray-100">
          {downloadUrl === null || downloadUrl?.includes("file-not-found") ? (
            <div className="flex flex-col p-5 w-full text-center">
              <span className="fs-4 text-danger">Unable to load file</span>
              <code className="fs-6 text-danger">File not found</code>
            </div>
          ) : (
            <iframe
              className="w-full min-h-screen"
              title="View document"
              border="0"
              src={pdfData?.bioLcGetGeneratedCertificate?.downloadUrl}
            ></iframe>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewCertificatePage;
