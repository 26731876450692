// once backend is ready put the gql here

export const siteRefId = {
  sweden: "c8f4261f9f46e6465709e17ebea7a92b",
  norway: "d5b9290a0b67727d4ba1ca6059dc31a6",
};

export const saf_urls = {
  swedenLandingPage: "/dashboard/sweden/saf", // This is new URL
  norwayLandingPage: "/dashboard/norway/saf", // This is new URL
  germanyLandingPage: "/dashboard/germany/saf",
  swedenDocumentPage: "/doc-manager/sweden",
  norwayDocumentPage: "doc-manager/norway",
  germanyDocumentPage: "doc-manager/germany",
  swedenMassBalance: "/mass-balance/list/sweden/saf",
  norwayMassBalance: "/mass-balance/list/norway/saf",
  germanyMassBalance: "/mass-balance/list/germany/saf",
  swedenReports: "saf/sweden/reports",
  norwayReports: "saf/norway/reports",
  germanyReports: "saf/germany/reports",
  swedenLinkToPurchase: "/saf/sweden/link-to-purchase",
  norwayLinkToPurchase: "/saf/norway/link-to-purchase",
  germanyLinkToPurchase: "/saf/germany/link-to-purchase",
  swedenMandateCertificate: "/mandate-certificate/sweden/saf",
  norwayMandateCertificate: "/mandate-certificate/norway/saf",
  germanyMandateCertificate: "/mandate-certificate/germany/saf",
};

export const DATE_RANGE_SIZE = 2;

export const CONFIG_CENTER_URL = "/config-center/coprocessing-spain";

export const actualisationErrorCodes = {
  VOID_LINKAGE: "ACT-0002",
  WRITE_OFF: "ACT-0003",
  REMINDER: "ACT-0004",
};

export const actualisationTypes = {
  WRITE_OFF: "write_off",
  REMINDER: "remainder",
};
