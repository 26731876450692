import React, {useEffect, useMemo, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Button} from "reactstrap";

import {useQuery} from "@apollo/client";
import {Filter24} from "@bphxd/ds-core-react/lib/icons";
import {NUMBER_OF_DECIMALS} from "constants/common";
import MANDATE_ALLOCATE_INCOMING from "graphql/mandate/bioLcCustomerMandateIncomingApi";
import MANDATE_ALLOCATE_OUTGOING from "graphql/mandate/bioLcCustomerMandateOutgoingApi";
import MANDATE_TOP_FILTERS from "graphql/mandate/bioLcGetMandateFiltersApi";
import {AllocationMandateAPI} from "graphql/mandate/bioLcMandateAllocation";
import {isEmpty, startCase} from "lodash";
import Layout from "modules/GlobalMassBalance/components/Layout";
import {useAppSetting} from "providers/appSetting";
import {useUserSettings} from "providers/userSettings";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting";
import {getFormattedNumberWithCheck} from "utils/numberUtil";
import CustomizeTable from "../components/MandateAllocateCustomizeTable";
import MandateAllocateModal from "../components/MandateAllocateModal";
import DocumentTable from "../components/MandateAllocateTable/DocumentTable";
import MandateFilter from "../components/MandateFilter";
import ResponseModal from "../components/ResponseModal";
import getMandateAllocationColumns from "../utils";

const MandateAllocation = () => {
  const [columnFilter, setColumnFilter] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [periodName, setPeriodName] = useState();
  const [mbTypeCode, setMbTypeCode] = useState();
  const [locationGroupName, setLocationGroupName] = useState();
  const [balanceGroupName, setBalanceGroupName] = useState();
  const [outboundTypeName, setOutboundTypeName] = useState();
  const [fetchApiFailed, setFetchApiFailed] = useState(false);
  const [incomingRowSelection, setIncomingRowSelection] = useState({});
  const [outgoingRowSelection, setOutgoingRowSelection] = useState({});
  const [filteredIncomingRows, setFilteredIncomingRows] = useState([]);
  const [filteredOutGoingRows, setFilteredOutGoingRows] = useState([]);
  const [totalRetiredQty, setTotalRetiredQty] = useState(0);
  const [totalAccruedQty, setTotalAccruedQty] = useState(0);
  const [showAllocateModal, setShowAllocateModal] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseHeader, setResponseHeader] = useState("");
  const [responseBody, setResponseBody] = useState("");
  const [responseFooter, setResponseFooter] = useState([]);
  const [mutationLoading, setMutationLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();
  const {appSetting} = useAppSetting();
  const navigate = useNavigate();

  const {country, division, location, period, outboundType} = useParams();

  const mbType = mbTypeCode?.toLowerCase();

  setCountryDetails(country);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionCode = division.toUpperCase();
  const divisionData = getDivisionData(divisionCode);
  const redirectUrl = `/mandate-certificate/${country}/${divisionCode}?location=${location}&period=${period}&outboundType=${outboundType}`;

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: divisionCode, link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },
    {text: "Mandate certificates", link: redirectUrl},
    {text: "Allocate for customer mandates"},
  ];

  const [defaultValues, setFilterDefaultValues] = useState({});
  const [currentFilterValues, setCurrentFilterValues] = useState(defaultValues);

  const {data: filterData, loading: topFiltersLoading} = useQuery(
    MANDATE_TOP_FILTERS,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
      },
      skip:
        siteReferenceData?.siteReferenceId === undefined ||
        divisionData?.divisionId === undefined,
    },
  );

  const {data: incomingData, loading: incomingLoading} = useQuery(
    MANDATE_ALLOCATE_INCOMING,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
        mbLocationGroupId: currentFilterValues?.mbLocationGroupId,
        outMovementType: currentFilterValues?.outboundTypeId,
        period: currentFilterValues?.periodId,
      },
      skip:
        siteReferenceData?.siteReferenceId === undefined ||
        divisionData?.divisionId === undefined ||
        currentFilterValues?.mbLocationGroupId === undefined ||
        currentFilterValues?.outboundTypeId === undefined ||
        currentFilterValues?.periodId === undefined,
    },
  );

  const {data: outgoingData, loading: outgoingLoading} = useQuery(
    MANDATE_ALLOCATE_OUTGOING,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
        mbLocationGroupId: currentFilterValues?.mbLocationGroupId,
        outMovementType: currentFilterValues?.outboundTypeId,
        period: currentFilterValues?.periodId,
      },
      skip:
        siteReferenceData?.siteReferenceId === undefined ||
        divisionData?.divisionId === undefined ||
        currentFilterValues?.mbLocationGroupId === undefined ||
        currentFilterValues?.outboundTypeId === undefined ||
        currentFilterValues?.periodId === undefined,
    },
  );

  const incomingTableData = useMemo(() => {
    return incomingData?.bioLcCustomerMandateIncomingApi?.documents;
  }, [incomingData]);

  const outGoingTableData = useMemo(() => {
    return outgoingData?.bioLcCustomerMandateOutgoingApi?.documents;
  }, [outgoingData]);

  const filterDynamicData = useMemo(
    () => filterData?.bioLcGetMandateFiltersApi?.results,
    [filterData?.bioLcGetMandateFiltersApi?.results],
  );

  const selectedData = useMemo(() => {
    const selectedLocation = filterDynamicData?.mbLocationGroups?.find(
      (item) => item.name === location,
    );
    const selectedPeriod = filterDynamicData?.periods?.find(
      (item) => item.name === period,
    );
    const selectedOutboundType = filterDynamicData?.outboundTypes?.find(
      (item) => item.name === outboundType,
    );

    return {
      selectedLocation,
      selectedPeriod,
      selectedOutboundType,
    };
  }, [filterDynamicData, location, period, outboundType]);

  useEffect(() => {
    if (period) {
      setPeriodName(period);
    }
    if (location) {
      setLocationGroupName(location);
    }
    if (outboundType) {
      setOutboundTypeName(outboundType);
    }
  }, [location, outboundType, period]);

  useEffect(() => {
    if (!isEmpty(selectedData)) {
      setFilterDefaultValues((defaultValuesItem) => {
        return {
          ...defaultValuesItem,
          mbLocationGroupId: selectedData?.selectedLocation?.id,
          periodId: selectedData?.selectedPeriod?.id,
          outboundTypeId: selectedData?.id,
        };
      });

      setCurrentFilterValues((defaultValuesItem) => {
        return {
          ...defaultValuesItem,
          mbLocationGroupId: selectedData?.selectedLocation?.id,
          mbLocationGroupName: selectedData?.selectedLocation?.name,
          periodId: selectedData?.selectedPeriod?.id,
          periodName: selectedData?.selectedPeriod?.name,
          outboundTypeId: selectedData?.selectedOutboundType?.id,
          outboundTypeName: selectedData?.selectedOutboundType?.name,
        };
      });
    }
  }, [selectedData]);

  // Get the incoming and outgoing columns based on the country, division, date format, and decimal format
  const {INCOMING_COLUMNS: incomingCols, OUTGOING_COLUMNS: outGoingCols} =
    useMemo(
      () =>
        getMandateAllocationColumns(
          country,
          division,
          dateFormat,
          decimalFormat,
          siteReferenceData?.siteReferenceId,
          divisionData?.divisionId,
        ),
      [
        country,
        dateFormat,
        decimalFormat,
        division,
        divisionData?.divisionId,
        siteReferenceData?.siteReferenceId,
      ],
    );

  // Set the initial state of incoming and outgoing columns
  const [incomingColumns, setIncomingColumns] = useState(incomingCols);
  const [outGoingColumns, setoutGoingColumns] = useState(outGoingCols);

  // Update the incoming and outgoing columns when the incomingCols or outGoingCols change
  useEffect(() => {
    if (incomingCols) {
      setIncomingColumns(incomingCols);
    }
    if (outGoingCols) {
      setoutGoingColumns(outGoingCols);
    }
  }, [incomingCols, outGoingCols, dateFormat]);

  // Filter the incoming columns to only include visible columns
  const filteredIncomingColumns = useMemo(() => {
    return [
      {
        header: incomingColumns?.[0]?.header,
        columns: incomingColumns?.[0]?.columns.filter(({visible}) => visible),
      },
    ];
  }, [incomingColumns]);

  // Filter the outgoing columns to only include visible columns
  const filteredoutGoingColumns = useMemo(() => {
    return [
      {
        header: outGoingColumns?.[0]?.header,
        columns: outGoingColumns?.[0]?.columns.filter(({visible}) => visible),
      },
    ];
  }, [outGoingColumns]);

  useEffect(() => {
    const calculateSum = (data, rowSelected, field) => {
      if (data.length > 0) {
        const isSelected = Object.keys(rowSelected).length > 0;
        const selectedRecords = isSelected
          ? Object.keys(rowSelected).map((item) =>
              data.find((ele) => ele.id === item),
            )
          : data;

        return selectedRecords.reduce(
          (sum, item) => sum + parseFloat(item.original[field]),
          0,
        );
      }
      return 0;
    };

    const sumRetired = calculateSum(
      filteredIncomingRows,
      incomingRowSelection,
      "retiredQty",
    );
    const sumAccured = calculateSum(
      filteredOutGoingRows,
      outgoingRowSelection,
      "accruedQuantity",
    );

    setTotalRetiredQty(sumRetired);
    setTotalAccruedQty(sumAccured);
  }, [
    incomingRowSelection,
    filteredIncomingRows,
    filteredOutGoingRows,
    outgoingRowSelection,
  ]);

  const isDependentDataNotAvailable =
    siteReferenceData?.siteReferenceId === undefined ||
    divisionData?.divisionId === undefined ||
    currentFilterValues?.mbLocationGroupId === undefined ||
    currentFilterValues?.outboundTypeId === undefined ||
    currentFilterValues?.periodId === undefined;

  const noData =
    (incomingTableData?.length === 0 && outGoingTableData?.length === 0) ||
    fetchApiFailed;
  const moreData =
    filteredIncomingRows?.length > 14 || filteredOutGoingRows?.length > 14;

  const inLoading =
    incomingLoading || topFiltersLoading || isDependentDataNotAvailable;

  const outLoading =
    outgoingLoading || topFiltersLoading || isDependentDataNotAvailable;

  const isIncomingSelected = Object.keys(incomingRowSelection).length > 0;
  const isPurchaseSelected = Object.keys(outgoingRowSelection).length > 0;

  const isOneCustomer =
    Object.keys(incomingRowSelection).length === 1 &&
    Object.keys(outgoingRowSelection).length === 1;

  const handleSuccessResponse = () => {
    setResponseHeader("Successfully allocated");
    setResponseBody("Successfully allocated purchase to customer mandate.");
    setResponseFooter([
      {
        id: 1,
        text: "Allocate another",
        buttonClassName: "!text-[#111]",
        action: () => {
          setIncomingRowSelection({});
          setOutgoingRowSelection({});
          setShowResponseModal(false);
        },
      },
      {
        id: 2,
        text: "Mandate certificates",
        buttonClassName: "!text-[#111111a3]",
        action: () => {
          navigate(redirectUrl);
        },
      },
    ]);
    setIncomingRowSelection({});
    setOutgoingRowSelection({});
    setShowAllocateModal(false);
    setShowResponseModal(true);
  };

  const handleAllocationError = () => {
    setResponseHeader("Customer mandates cannot be allocated");
    setResponseBody(
      "Please select the customer's accrued quantity to be the same as, or less, than the selected retired quantity.",
    );
    setResponseFooter([
      {
        id: 1,
        text: "Try again",
        buttonClassName: "!text-[#111]",
        action: () => {
          setIncomingRowSelection({});
          setOutgoingRowSelection({});
          setShowResponseModal(false);
        },
      },
      {
        id: 2,
        text: "Mandate certificates",
        buttonClassName: "!text-[#111111a3]",
        action: () => {
          navigate(redirectUrl);
        },
      },
    ]);
    setIncomingRowSelection({});
    setOutgoingRowSelection({});
    setShowAllocateModal(false);
    setShowResponseModal(true);
  };

  const customDateFormat = (date) => {
    const [day, month, year] = date.split("/");
    return `${year}-${month}`;
  };

  const handleLinkage = async (data) => {
    setMutationLoading(true);

    const inboundIds = Object.keys(incomingRowSelection)
      .map((item) => filteredIncomingRows.find((ele) => ele.id === item))
      .map((selectedItem) => selectedItem.original.mbOutboundQtyEuId);

    const outboundData = Object.keys(outgoingRowSelection)
      .map((item) => filteredOutGoingRows.find((ele) => ele.id === item))
      .map((selectedItem) => selectedItem.original);

    const allocationData = {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
      mbLocationGroupId: currentFilterValues?.mbLocationGroupId,
      period: currentFilterValues?.periodId,
      outMovementType: currentFilterValues?.outboundTypeId,
      mbOutboundEuIdsList: inboundIds,
      quantityAllocatedM3: data?.sustainableQtyM3,
      remarks: data?.notes,
      mbOutgoingFieldsList: outboundData.map((item) => ({
        uptoDate: customDateFormat(item.uptoDate),
        accruedQuantity: parseFloat(item.accruedQuantity),
        totalFuelQuantity: parseFloat(item.totalFuelQuantity),
        outMovementType: item.outMovementType,
        recipientName: item.recipientName,
      })),
    };

    const {data: responseData} = await AllocationMandateAPI({
      event: allocationData,
    });

    if (responseData?.bioLcMandateAllocation?.statusCode === 200) {
      handleSuccessResponse();
    } else {
      handleAllocationError();
    }
    setMutationLoading(false);
  };

  return (
    <Layout
      title="Allocate for customer mandates"
      breadcrumbs={breadcrumbItems}
    >
      {/* Top Filters */}
      <div className="flex flex-col justify-stretch w-full items-start px-7 pt-[32px]">
        <div className=" flex flex-row justify-between w-full mb-[24px]">
          <div className="flex flex-col justify-stretch w-full items-start">
            <MandateFilter
              currentFilterValues={currentFilterValues}
              filterDynamicData={filterDynamicData}
              allFiltersDisabled
            ></MandateFilter>
          </div>
          <div className="flex flex-none flex-row ml-5 mb-[8px] items-end">
            <Button
              data-test="allocate-outgoing-button"
              color="standard-tertiary rounded-0"
              type="button"
              onClick={() => navigate(redirectUrl)}
            >
              Back to mandate certificates
            </Button>
          </div>
        </div>
        <div className=" flex flex-row justify-between w-full p-4 bg-white">
          <div>
            <CustomizeTable
              columns={incomingColumns}
              onColumnChange={(cols) => setIncomingColumns(cols)}
              purchaseColumns={outGoingColumns}
              onPurchaseChange={(cols) => setoutGoingColumns(cols)}
              showApplyButton={false}
            />
          </div>
          <div className="flex flex-none flex-row items-center">
            {columnFilter.length > 0 && (
              <div className="mr-2">
                <Button
                  data-test="copro-filter-btn"
                  color="standard-tertiary rounded-0"
                  onClick={() => setColumnFilter([])}
                >
                  Clear
                </Button>
              </div>
            )}
            <div>
              <Button
                className={showFilter ? "border-black" : ""}
                data-test="copro-filter-btn"
                color="standard-tertiary rounded-0"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filters
                <Filter24 className="btn-icon-suffix" />
              </Button>
            </div>
          </div>
        </div>
        <div
          className={`flex border-t-[1px] border-[#ededed] w-full overflow-y-auto overflow-x-hidden ${
            noData ? "" : "h-[calc(100vh-640px)]"
          }`}
        >
          <div className="min-w-[50%] bg-white">
            <div className={`${noData ? "" : "overflow-x-auto"}`}>
              <DocumentTable
                columns={filteredIncomingColumns}
                data={fetchApiFailed ? [] : incomingTableData || []}
                className="incoming-docs-table"
                loading={inLoading}
                columnFilter={columnFilter}
                setColumnFilter={setColumnFilter}
                type="Incoming"
                enableRowSelection
                rowSelection={incomingRowSelection}
                setRowSelection={setIncomingRowSelection}
                otherData={fetchApiFailed ? [] : outGoingTableData || []}
                setFilteredIncomingRows={setFilteredIncomingRows}
                showFilter={showFilter}
              />
            </div>
          </div>
          <div
            style={{
              width: "2px",
              margin: "0 0.3px 0 0.7px",
              border: "solid 3px #fafafa",
            }}
          ></div>
          <div className="min-w-[50%] bg-white">
            <div className={`${noData ? "" : "overflow-x-auto"}`}>
              <DocumentTable
                columns={filteredoutGoingColumns}
                data={fetchApiFailed ? [] : outGoingTableData || []}
                className="incoming-docs-table"
                loading={outLoading}
                columnFilter={columnFilter}
                setColumnFilter={setColumnFilter}
                type="Outgoing"
                enableRowSelection
                rowSelection={outgoingRowSelection}
                setRowSelection={setOutgoingRowSelection}
                otherData={fetchApiFailed ? [] : incomingTableData || []}
                setFilteredOutGoingRows={setFilteredOutGoingRows}
                showFilter={showFilter}
              />
            </div>
          </div>
        </div>
        {noData && !fetchApiFailed && (
          <div className="w-full h-[calc(100vh-725px)] flex flex-col items-center mt-[-15px] text-center bg-white overflow-y-auto">
            <p className="mt-10 mb-[30px] w-[400px]">
              It appears that you have allocated all available outgoing
              transactions with purchases.
            </p>
            <p className="w-[400px]">
              Back to{" "}
              <Link to={redirectUrl} className="!underline text-black">
                mass balance
              </Link>{" "}
            </p>
          </div>
        )}
        {fetchApiFailed && (
          <div className="w-full h-[calc(100vh-725px)] flex flex-col items-center mt-[-15px] text-center bg-white overflow-y-auto">
            <p className="mt-10 mb-[30px] w-[400px]">
              Something went wrong. We&apos;re experiencing technical
              difficulties. Please try again later. If the issue persists,
              contact our support team for assistance.
            </p>
            <p className="w-[400px]">
              Back to{" "}
              <Link to={redirectUrl} className="!underline text-black">
                mass balance
              </Link>{" "}
            </p>
          </div>
        )}
        <div
          className={`w-full flex items-center h-16 bg-white z-10 ${
            moreData
              ? "[box-shadow:0_-6px_10px_0_rgba(17,_17,_17,_0.08)]"
              : "mt-[3px]"
          }`}
        >
          <div className="w-1/2 text-end">
            {filteredIncomingRows.length > 0 && !fetchApiFailed && (
              <>
                <span className="text-gray-700 mr-[10px]">
                  {Object.keys(incomingRowSelection).length > 0
                    ? "Selected Retired quantity"
                    : "Retired quantity"}
                </span>
                <span className="mr-[15px]">
                  {getFormattedNumberWithCheck(
                    totalRetiredQty,
                    NUMBER_OF_DECIMALS,
                  )}{" "}
                  m³
                </span>
              </>
            )}
          </div>
          <div className="w-1/2 text-end">
            {filteredOutGoingRows.length > 0 && !fetchApiFailed && (
              <>
                <span className="text-gray-700 mr-[10px]">
                  {Object.keys(outgoingRowSelection).length > 0
                    ? "Selected Accrued quantity"
                    : "Accrued quantity"}
                </span>
                <span className="mr-[15px]">
                  {getFormattedNumberWithCheck(
                    totalAccruedQty,
                    NUMBER_OF_DECIMALS,
                  )}{" "}
                  m³
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-stretch w-full items-start px-7 mt-6">
        <div className=" flex flex-row justify-end w-full mb-5">
          <Button
            color="tertiary"
            outline
            type="button"
            size="md"
            className="show link-btn rounded-0"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="secondary"
            className="float-right rounded-0 text-gray-400"
            size="md"
            disabled={!isIncomingSelected || !isPurchaseSelected}
            onClick={() => setShowAllocateModal(true)}
          >
            Allocate
          </Button>
        </div>
      </div>
      {showResponseModal && (
        <ResponseModal
          showModal={showResponseModal}
          header={responseHeader}
          body={responseBody}
          footerArr={responseFooter}
        />
      )}
      {showAllocateModal && (
        <MandateAllocateModal
          showModal={showAllocateModal}
          setShowModal={setShowAllocateModal}
          retiredQty={totalRetiredQty}
          accuredQty={totalAccruedQty}
          unitOfMeasure="m³"
          onSubmit={handleLinkage}
          loading={mutationLoading}
          isOneCustomer={isOneCustomer}
        />
      )}
    </Layout>
  );
};

export default MandateAllocation;
