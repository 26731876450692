import {Bioverse as bioverseConfig} from "content/navigations";
import {useAppSetting} from "providers/appSetting";
import {uniqBy} from "lodash";
import bioverseSafPowerBiConfig from "content/BioverseSafPowerBi";
import PropTypes from "prop-types";
import Navigation from "modules/navigation";
import useGlobalNotification from "providers/GlobalNotification/useGlobalNotification";
import {useCallback, useState, useEffect} from "react";
import {useQuery} from "@apollo/client";
import {LOCATION_DETAILS} from "graphql/config-center/LocationDetails";
import {useLocation} from "react-router-dom";
import {saf_urls} from "modules/SAF-dashboard/constants/common";

import Header from "./Header/HeaderBP";
import Notification from "./Notification";
import Side from "./Side";
import useRefreshSession from "./useRefreshSession";

const Layout = ({children}) => {
  const {appSetting} = useAppSetting();
  const [activeItem, setActiveItem] = useState("");
  const [isSideOpen, setSideOpen] = useState(false);
  const handleToggleSide = () => setSideOpen((prevValue) => !prevValue);
  const handleCloseMenu = () => setSideOpen(false);

  const {notificationProps} = useGlobalNotification();

  useRefreshSession();
  const SafPages = Object.values(saf_urls);
  const SafPowerBiPages = [
    "/saflandingpage",
    "/documentpage",
    "saf/dashboard",
    "/powerbi",
  ];
  const isPowerBi = process.env.REACT_APP_SAF_HIDDEN === "true";
  const urlsToUse = isPowerBi ? SafPowerBiPages : SafPages;
  const location = useLocation();
  const {pathname} = location;
  const isSaf = urlsToUse.some((page) => pathname.includes(page));
  const isGlobalConfigCenter = pathname.includes("/configuration-center");

  useEffect(() => {
    if (isSaf) {
      setSideOpen(false);
    }
    // side nav default behavior needs to be open=true for config center, resets to open=false for other modules.
    if (isGlobalConfigCenter) {
      setSideOpen(true);
    } else {
      setSideOpen(false);
    }
  }, [isSaf, isGlobalConfigCenter]);

  const {data: locationData, loading: locationLoading} =
    useQuery(LOCATION_DETAILS);

  const uniqueLocations = uniqBy(
    locationData?.bioLcCoproConfigCenterLocationDetails?.Records ?? [],
    "location_code",
  );

  const AppMenu = useCallback(() => {
    const menuConfig =
      isSaf && isPowerBi
        ? bioverseSafPowerBiConfig()
        : bioverseConfig(
            appSetting?.currentCountry,
            uniqueLocations,
            locationLoading,
            isGlobalConfigCenter ||
              appSetting?.featureFlags?.showGlobalConfigCenter,
          );
    const linkProps = {onClick: handleCloseMenu};
    return (
      <Navigation
        menuConfig={menuConfig}
        linkProps={linkProps}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        isGlobalConfigCenter={isGlobalConfigCenter}
      />
    );
  }, [
    activeItem,
    isPowerBi,
    isSaf,
    isGlobalConfigCenter,
    appSetting?.featureFlags?.showGlobalConfigCenter,
    appSetting?.currentCountry,
    uniqueLocations,
    locationLoading,
  ]);

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <Header
        onSideMenuItemClick={setActiveItem}
        onMenuClick={handleToggleSide}
        onLinkClick={handleCloseMenu}
      />
      <Notification {...notificationProps} className="m-4" />
      <section className="flex flex-row w-screen h-full overflow-scroll overflow-x-hidden">
        <Side isOpen={isSideOpen} onClose={handleCloseMenu}>
          <AppMenu />
        </Side>
        <div className="flex-1 min-h-screen min-w-0 bg-[#fafafa]">
          {children}
        </div>
      </section>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
