import {useQuery} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import {ReactSelectOverrides} from "@bphxd/ds-core-react";
import GET_COMPLIANCE_SCHEMES, {
  GET_POS_STATUS,
} from "graphql/rules-engine/rule-editor/getComplianceSchemes";
import PropTypes from "prop-types";
import {useUserSettings} from "providers/userSettings";
import {useEffect, useState} from "react";
import {Controller, useFormContext} from "react-hook-form";
import Select from "react-select";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import useRuleFormValidationEffects, {
  useRuleValidateOutputColumns,
} from "./validationError";

const CarbonIntensity = ({
  selectedItem,
  selectDatasetList,
  selectedDataset,
  selectedTemplateItems,
  conversionFactorList,
  updateConfigurationInBlocks,
  saveErrorData,
  isDisabled,
  ruleVersionId,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const {
    register,
    formState: {errors},
    control,
    setError,
    setFocus,
    setValue,
    clearErrors,
    watch,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const ComplianceScheme_Column = watch("compliance_scheme_id");
  const Sustainability_Column = watch("proof_of_sustanability");
  const watchDescription = watch("description");

  const {data} = useQuery(GET_COMPLIANCE_SCHEMES, {variables: {ruleVersionId}});
  const {data: posData} = useQuery(GET_POS_STATUS);

  const [posOptions, setPosOptions] = useState([]);

  useEffect(() => {
    if (posData) {
      const options =
        posData.bioLcComplianceRuleEngineGetPosStatus.posStatus.map(
          (status) => ({
            value: status.statusKey,
            label: status.statusText,
          }),
        );
      setPosOptions(options);
    }
  }, [posData]);

  // configuration values are set here

  useEffect(() => {
    updateConfigurationInBlocks(selectedItem, {
      compliance_scheme_id: ComplianceScheme_Column,
      proof_of_sustanability: Sustainability_Column || [],
      description: watchDescription,
    });
  }, [
    selectedItem,
    ComplianceScheme_Column,
    Sustainability_Column,
    watchDescription,
    updateConfigurationInBlocks,
  ]);

  useRuleValidateOutputColumns({
    field: "proof_of_sustanability",
    value: Sustainability_Column,
    selectedTemplateItems,
    clearErrors,
    setError,
    setFocus,
  });

  useEffect(() => {
    if (selectedItem && selectedItem?.configuration) {
      setValue(
        "compliance_scheme_id",
        selectedItem?.configuration?.compliance_scheme_id,
      );
      setValue(
        "proof_of_sustanability",
        selectedItem?.configuration?.proof_of_sustanability || [],
      );
      setValue("description", selectedItem?.configuration?.description);
    } else {
      setValue("compliance_scheme_id", "");
      setValue("proof_of_sustanability", []);
      setValue("description", "");
    }
  }, [selectedItem?.configuration, selectedItem, setValue]);

  useRuleFormValidationEffects({
    selectedItem,
    saveErrorData,
    clearErrors,
    setError,
    setFocus,
  });

  // function to pick multiple values from the dropdown

  return (
    <div className="flex flex-col gap-5 text-left">
      <FormGroup>
        <Label for="description" className="fw-normal">
          Description
        </Label>
        <Input
          type="input"
          id="description"
          data-test="description"
          {...computeProps("description")}
          maxLength={30}
          placeholder="Enter description"
          disabled={isDisabled}
        />
        <span className="absolute fw-normal right-1 p-2.5 text-xs">
          {watchDescription?.length || 0}/30
        </span>
      </FormGroup>
      <FormGroup>
        <Label for="compliance_scheme_id" className="fw-normal mb-4">
          Compliance scheme
        </Label>
        <Input
          type="select"
          id="compliance_scheme_id"
          data-test="compliance_scheme_id"
          {...computeProps("compliance_scheme_id", {
            required: "Please select input column",
          })}
          invalid={!!errors.compliance_scheme_id}
          disabled={isDisabled}
        >
          <option value="">Select product hierarchy level column</option>
          {data &&
            data.bioLcComplianceRuleEngineGetComplianceSchemes.complianceSchemes.map(
              (scheme) => (
                <option key={scheme.uuid} value={scheme.uuid}>
                  {scheme.complianceScheme}
                </option>
              ),
            )}
        </Input>
        {errors.compliance_scheme_id && (
          <FormFeedback className="mt-2">
            {errors.compliance_scheme_id?.message}
          </FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="proof_of_sustanability" className="fw-normal">
          Proof of sustainability
        </Label>
        <Controller
          name="proof_of_sustanability"
          control={control}
          render={({field}) => (
            <Select
              {...field}
              isMulti
              options={posOptions}
              components={ReactSelectOverrides.default}
              className="basic-multi-select"
              classNamePrefix="react-select"
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              placeholder="Select PoS status"
              isDisabled={isDisabled}
              value={Sustainability_Column}
            />
          )}
        />
        {errors.proof_of_sustanability && (
          <FormFeedback>{errors.proof_of_sustanability.message}</FormFeedback>
        )}
      </FormGroup>
    </div>
  );
};

CarbonIntensity.propTypes = {
  selectedItem: PropTypes.object,
  selectDatasetList: PropTypes.array,
  selectedDataset: PropTypes.string,
  selectedTemplateItems: PropTypes.array,
  conversionFactorList: PropTypes.array,
  updateConfigurationInBlocks: PropTypes.func,
  saveErrorData: PropTypes.object,
  isDisabled: PropTypes.bool,
  ruleVersionId: PropTypes.string,
};

export default CarbonIntensity;
