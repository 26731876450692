import PropTypes from "prop-types";
import {useUserSettings} from "providers/userSettings";
import {useEffect, useMemo} from "react";
import {useFormContext, useWatch} from "react-hook-form";

import {useAccount, useMsal} from "@azure/msal-react";
import {isNullOrEmptyOrUndefined, preventDecimal} from "utils/numberUtil";

import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import Datepicker from "../../Datepicker/index";
import useRuleFormValidationEffects from "./validationError";

const WriteDataOutput = ({
  field,
  index,
  selectedItem,
  writeDatasetList,
  selectedDataset,
  selectedTemplateItems,
  updateConfigurationInBlocks,
  saveErrorData,
  isDisabled,
  selectDatasetList,
  handleUpdateConfig,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const {
    register,
    formState: {errors},
    control,
    setError,
    setFocus,
    setValue,
    clearErrors,
    getValues,
    trigger,
    watch,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(`outputList.${index}.${name}`, options);
    return {innerRef: ref, ...props};
  };

  const changes = useWatch({
    name: [
      `outputList.${index}.manualInputFlag`,
      `outputList.${index}.outputColumn`,
      `outputList.${index}.manualInput`,
    ],
  });

  useEffect(() => {
    if (
      !isNullOrEmptyOrUndefined(changes[1]) ||
      !isNullOrEmptyOrUndefined(changes[2])
    ) {
      clearErrors(`output_columns, ${index}`);
    }
    handleUpdateConfig();
  }, [changes, handleUpdateConfig, clearErrors, index]);

  const configurationData = selectedTemplateItems.map(
    (item) => item.configuration,
  );

  const outputColumnsConfigurations = configurationData
    ?.map((config) => config?.output_column)
    ?.filter(
      (output) => output !== undefined && output !== null && output !== "",
    );

  const selectedDatasetInputColumnOptions =
    selectDatasetList?.find((x) => x.dataset === selectedDataset)?.schema || [];

  // get numeric/string columns
  const inputColumnOptions = selectedDatasetInputColumnOptions
    ?.filter((obj) => obj.type === field?.type)
    ?.map((obj) => obj.column);

  const outputColumns = useMemo(() => {
    return [...new Set(outputColumnsConfigurations), ...inputColumnOptions];
  }, [inputColumnOptions, outputColumnsConfigurations]);

  useRuleFormValidationEffects({
    selectedItem,
    saveErrorData,
    clearErrors,
    setError,
    setFocus,
  });

  // set the output column to empty if manual input is selected
  useEffect(() => {
    if (getValues(`outputList.${index}.manualInputFlag`) === true) {
      setValue(`outputList.${index}.outputColumn`, "");
    }
  }, [getValues, setValue, index, clearErrors, setError, field]);

  useEffect(() => {
    if (
      isNullOrEmptyOrUndefined(getValues(`outputList.${index}.outputColumn`))
    ) {
      const outputValue = outputColumns?.find(
        (x) => x?.toLowerCase() === field?.column?.toLowerCase(),
      );
      if (outputValue) {
        setValue(`outputList.${index}.outputColumn`, outputValue);
      }
    }
  }, [field, outputColumns, getValues, index, setValue]);

  const watchManualInputFlag = watch(`outputList.${index}.manualInputFlag`);

  return (
    <div>
      <div className="flex flex-row justify-between">
        <div className=" flex items-center justify-center ">
          {" "}
          <Label for="result_column" className="fw-normal !m-0">
            {field?.column} {field?.optional ? `(Optional)` : ""}
          </Label>
        </div>

        <FormGroup className="form-check-reverse  !mb-[10px]" switch>
          <Label check>Manual input</Label>
          <Input
            type="switch"
            id={`${field.id}manualInputFlag`}
            data-test={`${field.id}manualInputFlag`}
            {...computeProps("manualInputFlag", {})}
          />
        </FormGroup>
      </div>
      {!watchManualInputFlag && (
        <FormGroup>
          <Input
            type="select"
            id={`${field.id}outputColumn`}
            data-test={`${field.id}outputColumn`}
            {...computeProps("outputColumn", {
              required:
                field?.optional === false
                  ? "Please select the option"
                  : undefined,
            })}
            invalid={!!errors.outputList?.[index]?.outputColumn && !isDisabled}
            disabled={isDisabled}
          >
            <option value="">Select column</option>
            {outputColumns?.map((outputColumn) => (
              <option key={outputColumn} value={outputColumn}>
                {outputColumn}
              </option>
            ))}
          </Input>
          {errors.outputList?.[index]?.outputColumn && (
            <FormFeedback>
              {errors.outputList?.[index]?.outputColumn.message}
            </FormFeedback>
          )}
        </FormGroup>
      )}
      {watchManualInputFlag && (
        <FormGroup>
          {field?.type === "numeric" && (
            <Input
              type="number"
              id={`${field.id}manualInput`}
              data-test={`${field.id}manualInput`}
              {...computeProps("manualInput", {
                required:
                  field?.optional === false
                    ? "Please enter the value"
                    : undefined,
              })}
              invalid={!!errors.outputList?.[index]?.manualInput && !isDisabled}
              disabled={isDisabled}
            ></Input>
          )}
          {field?.type === "string" && (
            <Input
              type="text"
              id={`${field.id}manualInput`}
              data-test={`${field.id}manualInput`}
              {...computeProps("manualInput", {
                required:
                  field?.optional === false
                    ? "Please enter the value"
                    : undefined,
              })}
              invalid={!!errors.outputList?.[index]?.manualInput && !isDisabled}
              disabled={isDisabled}
            ></Input>
          )}
          {field?.type === "date" && (
            <Datepicker
              id={`${field.id}manualInput`}
              data-test={`${field.id}manualInput`}
              {...computeProps("manualInput", {
                required:
                  field?.optional === false
                    ? "Please select the date"
                    : undefined,
              })}
              control={control}
              placeholder="Please add date if available"
              disabled={isDisabled}
              invalid={!!errors.outputList?.[index]?.manualInput && !isDisabled}
            />
          )}
          {field?.type === "integer" && (
            <Input
              type="number"
              id={`${field.id}manualInput`}
              data-test={`${field.id}manualInput`}
              {...computeProps("manualInput", {
                required:
                  field?.optional === false
                    ? "Please enter the value"
                    : undefined,
              })}
              invalid={!!errors.outputList?.[index]?.manualInput && !isDisabled}
              disabled={isDisabled}
              onKeyDown={(e) => preventDecimal(e)}
            ></Input>
          )}
          {errors.outputList?.[index]?.manualInput && (
            <FormFeedback>
              {errors.outputList?.[index]?.manualInput.message}
            </FormFeedback>
          )}
        </FormGroup>
      )}
      {errors && errors[`output_columns, ${index}`] && (
        <p className="text-[#9e3232] text-[14px] opacity-100 my-4">
          {" "}
          {errors && errors[`output_columns, ${index}`]?.message}
        </p>
      )}
    </div>
  );
};

WriteDataOutput.propTypes = {
  field: PropTypes.object,
  index: PropTypes.number,
  selectedItem: PropTypes.object,
  writeDatasetList: PropTypes.array,
  selectedDataset: PropTypes.string,
  selectedTemplateItems: PropTypes.array,
  updateConfigurationInBlocks: PropTypes.func,
  saveErrorData: PropTypes.object,
  isDisabled: PropTypes.bool,
  selectDatasetList: PropTypes.array,
  handleUpdateConfig: PropTypes.func,
};

export default WriteDataOutput;
